import React, { useEffect, useState } from "react";
import firebase from "firebase";
import { useCollection } from "react-firebase-hooks/firestore";
import { Button, Modal, Form } from "react-bootstrap";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

function TableCell(props) {
  const { rowData, headData, darkTheme, currentEvent, graphId, headLength } =
    props;
  let column = headData.id,
    row = rowData.id;

  const [showAddValueModal, setShowAddValueModal] = useState(false);
  const [newCellValue, setNewCellValue] = useState("");
  const [selectedRow, setSelectedRow] = useState("");
  const [selectedColumn, setSelectedColumn] = useState("");
  const [isCellValueEdit, setIsCellValueEdit] = useState(false);
  const [editValue, setEditValue] = useState("");
  const [addNote, setAddNote] = useState(false);
  const [cellValuesArray, setCellValuesArray] = useState([]);
  const [valuesCollection, loading, error] = useCollection(
    firebase
      .firestore()
      .collection(
        `events/${currentEvent?.id}/bboa/${graphId}/buyer_exp_audit/0/columns/${column}/values/${row}/values`
      )
  );

  useEffect(() => {
    if (valuesCollection) {
      const valuesArray = valuesCollection?.docs?.map((item) => {
        return { ...item.data(), id: item.id };
      });
      setCellValuesArray(valuesArray);
    }
  }, [valuesCollection]);

  const deleteCellValueHandler = (e, item) => {
    e.preventDefault();
    if (item) {
      firebase
        .firestore()
        .collection(
          `/events/${currentEvent?.id}/bboa/${graphId}/buyer_exp_audit/0/columns/${selectedColumn.id}/values/${selectedRow.id}/values`
        )
        .doc(`${item.id}`)
        .delete()
        .then(() => {
          toast.success("Deleted successfully", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
        });
    }
  };

  const editCellValueHandler = (e) => {
    e.preventDefault();
    if (editValue) {
      firebase
        .firestore()
        .collection(
          `/events/${currentEvent?.id}/bboa/${graphId}/buyer_exp_audit/0/columns/${selectedColumn.id}/values/${selectedRow.id}/values`
        )
        .doc(`${editValue.id}`)
        .set(
          {
            note: editValue.note ?? "",
          },
          {
            merge: true,
          }
        )
        .then(() => {
          setIsCellValueEdit(false);
        });
    }
  };

  const addCellValueHandler = (e) => {
    e.preventDefault();
    // console.log(selectedRow, selectedColumn, newCellValue)
    let user = firebase.auth().currentUser;
    let data = {
      note: newCellValue,
      created_at: new Date(),
      created_by: {
        name: user.displayName,
        email: user.email,
        id: user.uid,
      },
    };
    if (newCellValue) {
      firebase
        .firestore()
        .collection(
          `/events/${currentEvent?.id}/bboa/${graphId}/buyer_exp_audit/0/columns/${selectedColumn.id}/values/${selectedRow.id}/values`
        )
        .add(data)
        .then(() => {
          setSelectedRow(null);
          setSelectedColumn(null);
          setNewCellValue("");
          setShowAddValueModal(false);
        });
    }
  };

  if (headData.id == 0)
    return (
      <th
        className={
          darkTheme ? "bg-white border border-light col-5" : "bg-white border border-dark col-5"
        }
      >
        <div className="d-flex align-items-center">
          {headLength <= 8 && (
            <div className="mr-2">
              <img
                className="img-fluid"
                src={`https://igesia-static.s3.amazonaws.com/images/bboa/${rowData.logo}.png`}
                style={{
                  height: 40,
                  width: 40,
                }}
              />
            </div>
          )}
          <div className="flex-grow-1">
            <p
              className="mb-0"
              style={
                rowData.type === 1 ? { color: "#ec3cd5" } : { color: "#9696ff" }
              }
            >
              {rowData.name}
            </p>
            <p className="mb-0 small" style={{ color: "#0a4f79" }}>
              {rowData.description}
            </p>
          </div>
        </div>
      </th>
    );
  else
    return (
      <>
        <td
          className={
            darkTheme
              ? headLength > 4
                ? "bg-white border border-light col-2"
                : "bg-white border border-light col"
              : headLength > 4
              ? "bg-white border border-dark col-2"
              : "bg-white border border-dark col"
          }
          onClick={() => {
            // console.log( rowData, headData )
            setSelectedRow(rowData);
            setSelectedColumn(headData);
            setShowAddValueModal(true);
          }}
        >
          {loading ? (
            "Loading..."
          ) : (
            <div className="row px-2">
              {cellValuesArray.map((item) => {
                return (
                  <p className="m-1" style={{ color: "red" }}>
                    {`X`}
                  </p>
                );
              })}
            </div>
          )}
        </td>
        <>
          <Modal
            centered
            show={showAddValueModal}
            onHide={() => {
              setShowAddValueModal(false);
              setAddNote(false);
            }}
          >
            <Modal.Header data-theme={darkTheme ? "dark" : "light"} closeButton>
              {selectedColumn && (
                <div>
                  <p className="mb-0 small font-weight-bold text-uppercase text-muted">
                    Stage
                  </p>
                  <p className="lead mb-0">{selectedColumn.name}</p>
                </div>
              )}
            </Modal.Header>
            <Modal.Body data-theme={darkTheme ? "dark" : "light"}>
              {selectedRow && (
                <div className="d-flex align-items-center mb-3">
                  <div className="mr-2">
                    <img
                      className="img-fluid"
                      src={`https://igesia-static.s3.amazonaws.com/images/bboa/${selectedRow.logo}.png`}
                      style={{
                        height: 40,
                        width: 40,
                      }}
                    />
                  </div>
                  <div className="flex-grow-1">
                    <p
                      className="mb-0"
                      style={
                        selectedRow.type === 1
                          ? { color: "#ec3cd5" }
                          : { color: "#9696ff" }
                      }
                    >
                      {selectedRow.name}
                    </p>
                    <p className="mb-0 small" style={{ color: "#0a4f79" }}>
                      {selectedRow.description}
                    </p>
                  </div>
                </div>
              )}
              <div className="mb-3">
                {cellValuesArray?.length > 0 ? (
                  cellValuesArray.map((item, index) => {
                    return (
                      <div className="d-flex border rounded p-1 pl-2 mb-1">
                        {!isCellValueEdit ? (
                          <>
                            <p className="m-1 flex-grow-1">
                              {`${index + 1}. ${item.note} (${
                                item.created_by.name
                              })`}
                            </p>
                            <Button
                              onClick={() => {
                                setIsCellValueEdit(true);
                                setEditValue(item);
                              }}
                              size="sm"
                              variant="primary"
                              className="mr-1"
                            >
                              <div>
                                <FontAwesomeIcon size="sm" icon={faEdit} /> Edit
                              </div>
                            </Button>
                            <Button
                              onClick={(e) => {
                                deleteCellValueHandler(e, item);
                              }}
                              size="sm"
                              variant="danger"
                            >
                              <div>
                                <FontAwesomeIcon size="sm" icon={faTrash} />{" "}
                                Delete
                              </div>
                            </Button>
                          </>
                        ) : (
                          <>
                            <Form.Control
                              type="text"
                              placeholder="Edit Value"
                              value={editValue?.note}
                              onChange={(e) => {
                                setEditValue((prev) => {
                                  const newState = {
                                    ...prev,
                                    note: e.target.value,
                                  };
                                  return newState;
                                });
                              }}
                            />
                            <Button
                              onClick={(e) => {
                                editCellValueHandler(e);
                              }}
                              size="sm"
                              variant="primary"
                              className="mx-2"
                            >
                              <div>Save</div>
                            </Button>
                            <Button
                              size="sm"
                              variant="secondary"
                              onClick={() => {
                                setIsCellValueEdit(false);
                                setEditValue(null);
                              }}
                            >
                              Discard
                            </Button>
                          </>
                        )}
                      </div>
                    );
                  })
                ) : (
                  <p className="text-center">
                    <i>No notes added yet</i>
                  </p>
                )}
              </div>
              {addNote && (
                <Form>
                  <Form.Group className="mb-3">
                    <Form.Label className="small font-weight-bold text-uppercase text-muted mb-1">
                      Add Note
                    </Form.Label>
                    <Form.Control
                      type="text"
                      autoComplete="off"
                      placeholder="Enter Note to add"
                      value={newCellValue}
                      onChange={(e) => setNewCellValue(e.target.value)}
                    />
                  </Form.Group>
                </Form>
              )}
              <div className="d-flex">
                {addNote && (
                  <>
                    <Button
                      className="mr-2"
                      variant="primary"
                      onClick={(e) => addCellValueHandler(e)}
                    >
                      Save
                    </Button>
                    <Button
                      variant="secondary"
                      onClick={() => {
                        setShowAddValueModal(false);
                        setAddNote(false);
                        setNewCellValue("");
                      }}
                    >
                      Discard
                    </Button>
                  </>
                )}
                {!addNote && (
                  <Button variant="primary" onClick={() => setAddNote(true)}>
                    Add Note
                  </Button>
                )}
              </div>
            </Modal.Body>
          </Modal>
        </>
      </>
    );
}

export default connect(
  (state) => ({
    graphId: state.graphId,
    currentEvent: state.currentEvent,
    darkTheme: state.darkTheme,
  }),
  null
)(TableCell);
