import React, { memo, useState, useEffect } from "react";
import {
  faAngleDown,
  faAngleUp,
  faImages,
  faTimes,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import produce from "immer";
import {
  Button,
  Card,
  Form,
  FormControl,
  InputGroup,
  Accordion,
  Spinner,
} from "react-bootstrap";
import firebase from "firebase";
import { toast } from "react-toastify";
import axios from "axios";
import { connect } from "react-redux";
import _ from "lodash";
import moment from "moment";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
  ADMIN_QUIZ_CREATE,
  API_BASE_URL,
  DRIVE_UPLOAD,
  QUIZ_CREATE,
  QUIZ_EDIT,
} from "../../../../../../config";
import DateTimePicker from "../../../../../DateTimePicker";
import { auth } from "../../../../../../firebase";
import { postData } from "../../../../../../utils";

const Create = (props) => {
  const { isEditting, quizData, id, currentEvent, eventId, module_id } = props;
  const [quizTitle, setQuizTitle] = useState("");
  const [questions, setQuestions] = useState([]);
  const [options, setOptions] = useState([]);
  const [correctAnswers, setCorrectAnswers] = useState([]);
  const [types, setTypes] = useState([]);
  const [isMultiple, setIsMultiple] = useState(false);
  const [correctOptionsIndex, setCorrectOptionsIndex] = useState([0]);
  const [saving, setSaving] = useState(false);
  const userDetails = auth.currentUser;
  const [graded, setGraded] = useState(false);
  const [quizGradepoints, setQuizGradepoints] = useState(0);
  const [questionsGradepoints, setQuestionsGradepoints] = useState([]);
  const [addDeadline, setAddDeadline] = useState(false);
  const [deadline, setDeadline] = useState(new Date(Date.now() + 86400000));
  const [startDateCalendar, setStartDateCalendar] = useState(false);
  const [questionsImages, setQuestionsImages] = useState([]);
  const [quizQuestionsImages, setQuizQuestionsImages] = useState([]);

  useEffect(() => {
    if (props?.quizData) {
      let questionArray = Object.values(quizData.quizQuestions)
        .sort((a, b) => a.sequenceNo - b.sequenceNo)
        .map((item) => item.question);

      setQuizQuestionsImages(
        Object.values(quizData.quizQuestions)
          ?.sort((a, b) => a.sequenceNo - b.sequenceNo)
          ?.map((e) => e.questionImage)
      );

      // Setting while edit: Deadline
      setDeadline(props?.quizData?.deadline);
      setAddDeadline(true);

      let optionsArray = Object.values(quizData.quizQuestions)
        .sort((a, b) => a.sequenceNo - b.sequenceNo)
        .map((item) => item.options);

      let op2 = optionsArray.map((item) =>
        Object.values(item).map((item) => item.optionValue)
      );

      let questionGradepointsArray = Object.values(quizData.quizQuestions)
        .sort((a, b) => a.sequenceNo - b.sequenceNo)
        .map((item) => parseInt(item.weight));

      let typesArray = Object.values(quizData.quizQuestions)
        .sort((a, b) => a.sequenceNo - b.sequenceNo)
        .map((item) => (parseInt(item.type) === 1 ? "multiple" : "single"));

      let allAnswers = optionsArray.map((item) => {
        let answers = [];
        Object.values(item).forEach((option) => {
          if (option.correctAnswer) {
            answers.push(option.optionValue);
          }
        });
        return answers;
      });
      setCorrectAnswers(allAnswers);
      setOptions(op2);
      setQuizTitle(quizData?.quizName);
      setQuestions(questionArray);
      setGraded(quizData?.isGraded);
      setQuizGradepoints(
        questionGradepointsArray?.reduce((a, b) => parseInt(a) + parseInt(b))
      );
      setQuestionsGradepoints(questionGradepointsArray);
      setTypes(typesArray);
    }
  }, [props?.quizData]);

  const uploadFile = async (file) => {
    const fileData = new FormData();
    fileData.append("file", file, `${file.name}-${Date.now()}`);
    fileData.append("user_id", userDetails.uid);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    if (file) {
      try {
        let res = await axios.post(
          `${API_BASE_URL}${DRIVE_UPLOAD}`,
          fileData,
          config
        );
        return res.data.data["file_url"];
      } catch (error) {
        console.error(error, "error in writing file to db");
      }
    } else return "";
  };

  const addQuestion = () => {
    let prevQuestion = [...questions];
    prevQuestion[prevQuestion.length] = "";
    setQuestions(prevQuestion);

    let prevOptions = [...options];
    prevOptions[prevOptions.length] = [""];
    setOptions(prevOptions);

    let prevCorrectAnswer = [...correctAnswers];
    prevCorrectAnswer[prevCorrectAnswer.length] = 0;
    setCorrectAnswers(prevCorrectAnswer);

    let prevTypes = [...types];
    prevTypes[prevTypes.length] = "single";
    setTypes(prevTypes);
  };

  const makeOptionsHandler = () => {
    let optionsModified = {};
    options.forEach((option, index) => {
      optionsModified = {
        ...optionsModified,
        [Math.floor(100000 + Math.random() * 900000)]: {
          optionImage: null,
          correctAnwser: correctOptionsIndex.includes(index),
          optionValue: option.optionValue,
          sequenceNo: index + 1,
        },
      };
    });
    return optionsModified;
  };

  const handleOptions = (e, key, k) => {
    let prevOptions = [...options];
    let thatOption = [...prevOptions[key]];

    thatOption[k] = e.target.value;
    prevOptions[key] = thatOption;
    // console.log(prevOptions);
    setOptions(prevOptions);
  };

  const handleAnswer = (val, key, optionValue) => {
    let prev = [...correctAnswers];

    if (types[key] === "single") {
      prev[key] = optionValue ? optionValue : options[key][val];
    } else {
      let temp = prev[key] ? prev[key] : [];
      prev[key] = temp.includes(optionValue)
        ? temp.filter((item) => item !== optionValue)
        : [...temp, optionValue];
    }
    setCorrectAnswers(prev);
  };

  const addOption = (i) => {
    //console.log(options, i);
    if (options[i].length < 8) {
      let prevOptions = [...options];
      let thatOption = prevOptions[i];
      prevOptions[i] = [...thatOption, ""];
      setOptions(prevOptions);
    } else {
      console.log("Max options length(8) exceeded");
      return;
    }
  };

  const handleTypes = (val, key) => {
    let prev = [...types];
    prev[key] = val ? "multiple" : "single";
    setTypes(prev);
  };

  // HANDLES GRADE POINTS ADDED
  const handleGradepoints = (e, key) => {
    let prev = [...questionsGradepoints];
    prev[key] = parseInt(e.target.value);
    setQuestionsGradepoints(prev);
  };

  const removeOption = (key, k) => {
    let prevOptions = [...options];
    let thatOption = prevOptions[key];
    let prevAnswers = [...correctAnswers];
    if (thatOption[k] === prevAnswers[key]) {
      prevAnswers[key] = thatOption[k + 1] ? thatOption[k + 1] : 0;
      thatOption.splice(k, 1);
      setCorrectAnswers(prevAnswers);
    } else {
      thatOption.splice(k, 1);
    }
    prevOptions[key] = thatOption;
    setOptions(prevOptions);
  };

  const generateQuestions = async (questions) => {
    let quiz_questions = [];
    let newOptions = [];
    newOptions = options.map((option, key) =>
      option.map((opt, k) => {
        if (correctAnswers[key] === opt || correctAnswers[key].includes(opt)) {
          return {
            option_value: opt,
            correct_answer: true,
            option_image: null,
            sequence_no: k + 1,
          };
        } else {
          return {
            option_value: opt,
            correct_answer: false,
            option_image: null,
            sequence_no: k + 1,
          };
        }
      })
    );

    let allImagesUploadedPromises = isEditting
      ? quizQuestionsImages.map((file, key) => {
          if (questionsImages[key]) {
            return uploadFile(questionsImages[key]);
          } else {
            return quizQuestionsImages[key];
          }
        })
      : questionsImages.map((file, key) => {
          if (file) {
            return uploadFile(file);
          } else {
            return "";
          }
        });
    let questionImageList = [];

    await Promise.all(allImagesUploadedPromises).then(async (values) => {
      questionImageList = values;
    });

    questions.map((question, key) =>
      quiz_questions.push({
        sequence_no: key + 1,
        question,
        questionImage: questionImageList[key] ?? "",
        type: types[key] === "multiple" ? 1 : 0,
        options: newOptions[key],
        //correctAnswer: correct_answers[key],
        weight: questionsGradepoints[key],
      })
    );
    return quiz_questions;
  };

  const addQuiz = async () => {
    try {
      setSaving(true);

      const quiz_data = {
        user_id: `${userDetails?.uid}`,
        workspace_id: currentEvent?.workspace_id,
        room_id: currentEvent?.room_id,
        deadline: addDeadline
          ? moment(deadline).valueOf()
          : Date.now() + 2 * 365 * 24 * 60 * 60 * 1000, //false,
        quiz_name: quizTitle,
        questions: await generateQuestions(questions),
        all_participants: true,
        assigned: [],
        folderId: "",
        save_for_later: false,
        is_graded: graded || false,
        product_id: eventId,
        parent_id: module_id,
      };

      const response = await axios.post(
        `${API_BASE_URL}${ADMIN_QUIZ_CREATE}`,
        quiz_data
      );

      if (response.status === 200) {
        toast.success("Quiz added successfully", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
        });

        setQuizTitle("");
        setQuestions([]);
        setOptions([]);
        setCorrectAnswers([]);
        setTypes([]);
        setGraded(false);
        setQuizGradepoints(0);
        setQuestionsGradepoints([]);
        setAddDeadline(false);
        setDeadline(new Date(Date.now() + 86400000));
        setStartDateCalendar(false);

        props.handleRefresh();
      }
    } catch (error) {
      console.error("[Error] Creating Quiz: ", error);
      toast.error(`Error creating Quiz: ${error.message}`, {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
    } finally {
      setSaving(false);
    }
  };

  const editQuiz = async () => {
    try {
      setSaving(true);

      const quiz_data = {
        user_id: `${userDetails?.uid}`,
        workspace_id: currentEvent?.workspace_id,
        room_id: currentEvent?.room_id,
        deadline: deadline, //false,
        quiz_name: quizTitle,
        published_quiz_id: quizData?.id,
        questions: await generateQuestions(questions),
        all_participants: true,
        assigned: [],
        folderId: "",
        save_for_later: false,
        is_graded: graded || false,
        product: "event",
      };

      const response = await postData(`${API_BASE_URL}${QUIZ_EDIT}`, quiz_data);

      await firebase
        .firestore()
        .collection(`/events/${eventId}/quizzes`)
        .doc(`${id}`)
        .update(
          {
            title: quizTitle,
            updated_at: Date.now(),
            is_graded: graded || false,
            total_points: quizGradepoints,
            deadline: deadline,
            type: 4,
          },
          {
            merge: true,
          }
        );

      if (response.status === 200) {
        toast.success("Quiz updated successfully", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
        });
        props.handleCloseItem();
        props.handleRefresh();
      } else {
        toast.error(`${response.message}`, {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
        });
      }
    } catch (e) {
      console.error("Error : ", e);
      toast.error(`Error: ${e.message}`, {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
    } finally {
      setSaving(false);
    }
  };

  useEffect(() => {
    if (questionsGradepoints?.length > 0) {
      setQuizGradepoints(
        questionsGradepoints?.reduce((a, b) => {
          return parseInt(a) + parseInt(b);
        })
      );
    }
  }, [questionsGradepoints]);

  return (
    <Form>
      <div className="rounded-lg p-2 m-2">
        <p className="mb-2 font-weight-bold text-theme small">Quiz Title</p>
        <InputGroup className="mb-3">
          <FormControl
            size="sm"
            placeholder="Quiz Title"
            aria-label="Quiz Title"
            aria-describedby="quiz-title"
            name="quizTitle"
            value={quizTitle}
            autoFocus={true}
            onChange={(e) => setQuizTitle(e.target.value)}
          />
        </InputGroup>

        <div className="mb-2">
          <Form.Check
            type="switch"
            id="gradebook-switch"
            label="Add to Gradebook"
            value="graded"
            checked={graded}
            onChange={(e) => {
              if (e.target.checked) setGraded(true);
              else setGraded(false);
            }}
          />
          {graded && (
            <>
              <Form.Text className="small text-muted mb-2">
                <b>Note:</b> Provide grade points for each question to add them
                to Gradebook.
              </Form.Text>
              <InputGroup>
                <FormControl
                  placeholder="Gradepoints"
                  name="quizGradepoints"
                  type="number"
                  min="0"
                  disabled={true}
                  // onChange={(e) =>
                  //   setQuizGradepoints(parseInt(e.target.value))
                  // }
                  value={quizGradepoints}
                />
                <InputGroup.Append>
                  <InputGroup.Text>Total points</InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
            </>
          )}
        </div>

        <div className="mb-3">
          <Form.Group controlId="formBasicCheckbox">
            <Form.Check
              type="checkbox"
              label="Add deadline"
              onChange={(e) => {
                setAddDeadline(e.target.checked);
                setDeadline(new Date(Date.now() + 86400000));
              }}
              checked={addDeadline}
            />
          </Form.Group>
          {
            addDeadline && (
              // (deadline && !startDateCalendar ? (
              //   <a
              //     className={
              //       isEditting
              //         ? "border border-secondary rounded p-2 text-dark pointer"
              //         : "border border-secondary rounded p-2 text-white pointer"
              //     }
              //     onClick={() => setStartDateCalendar(true)}
              //     style={{ textDecoration: "none" }}
              //   >
              //     Deadline: {moment(deadline).format("DD MMMM, YYYY hh:mm A")}
              //   </a>
              // ) : (
              // <DateTimePicker
              //   startDateCalendar={startDateCalendar}
              //   callbackOpen={() => setStartDateCalendar(true)}
              //   callbackClose={() => setStartDateCalendar(false)}
              //   currentValue={deadline}
              //   callbackSet={setDeadline}
              // />
              <div className="d-flex align-items-center">
                <span className="mr-2">Deadline:</span>
                <DatePicker
                  selected={new Date(deadline)}
                  onChange={(date) => setDeadline(moment(date).valueOf())}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={1}
                  timeCaption="time"
                  dateFormat="MMMM d, yyyy h:mm aa"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
              </div>
            )
            // ))
          }
        </div>

        {questions.length > 0 &&
          questions?.map((question, key) => (
            <Card
              className={isEditting ? "mb-2 bg-light" : "mb-2 bg-dark"}
              key={key}
            >
              <Card.Header className="d-flex align-items-center">
                <div className="flex-grow-1 pointer">
                  <p className="mb-0 flex-grow-1 small font-weight-bold mb-0">
                    Question {key + 1}
                  </p>
                </div>
                <div
                  className="pointer mt-1 mr-1"
                  title="Delete"
                  onClick={() => {
                    setQuestions((prev) => {
                      const updatedState = produce(prev, (draft) => {
                        draft.splice(key, 1);
                      });
                      return updatedState;
                    });

                    setQuestionsGradepoints((prev) => {
                      const updatedState = produce(prev, (draft) => {
                        draft.splice(key, 1);
                      });
                      return updatedState;
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faTrashAlt} title="Delete" />
                </div>
              </Card.Header>
              <Card.Body className="p-2">
                <Form.File className="mb-2">
                  <Form.File.Label>Question image</Form.File.Label>
                  {isEditting && quizQuestionsImages[key] && (
                    <div className="mb-2">
                      <figure>
                        <Zoom>
                          <img
                            src={quizQuestionsImages[key]}
                            className="img-fluid w-100 rounded-sm"
                            style={{ height: 120 }}
                          />
                        </Zoom>
                      </figure>
                      <Button
                        size="sm"
                        variant="danger"
                        onClick={() =>
                          setQuizQuestionsImages((prev) =>
                            prev?.map((e, idx) => {
                              if (idx === key) return "";
                              else return e;
                            })
                          )
                        }
                      >
                        Remove Image
                      </Button>
                    </div>
                  )}
                  <Form.File.Input
                    accept="image/*"
                    onChange={(e) => {
                      setQuestionsImages((prev) => {
                        let newState = [...prev];
                        newState[key] = e.target.files[0];
                        return newState;
                      });
                    }}
                  />
                </Form.File>

                <InputGroup className="mb-1">
                  <FormControl
                    placeholder="Question"
                    aria-label="Question"
                    aria-describedby={"question" + key}
                    as="textarea"
                    name="questions"
                    id={key}
                    required={true}
                    value={question}
                    onChange={(e) =>
                      setQuestions((prev) => {
                        const updatedState = produce(prev, (draft) => {
                          draft[key] = e.target.value;
                        });
                        return updatedState;
                      })
                    }
                  />
                </InputGroup>

                {graded && (
                  <InputGroup>
                    <FormControl
                      placeholder="Grade points"
                      name="gradepoints[]"
                      type="number"
                      min="1"
                      id={key}
                      onChange={(e) => handleGradepoints(e, key)}
                      value={questionsGradepoints[key]}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>points</InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                )}

                <Form.Group className="mb-1">
                  <Form.Check
                    label="Multiple answers"
                    value={types[key] === "multiple" ? true : false}
                    checked={types[key] === "multiple" ? true : false}
                    onChange={(e) => {
                      handleTypes(
                        types[key] === "multiple" ? false : true,
                        key
                      );
                    }}
                  />
                </Form.Group>
                {options[key]?.map((option, k) => (
                  <>
                    <InputGroup className="mb-1" key={k}>
                      <InputGroup.Prepend>
                        <InputGroup.Text>
                          {options[key][k] && (
                            <Form.Check
                              onChange={() => handleAnswer(k, key, option)}
                              type={
                                types[key] === "single" ? "radio" : "checkbox"
                              }
                              checked={
                                types[key] === "single"
                                  ? `${correctAnswers[key]}` === `${option}`
                                    ? true
                                    : false
                                  : correctAnswers[key] &&
                                    (correctAnswers[key].includes(option) ||
                                      correctAnswers[key].includes(k))
                                  ? true
                                  : false
                              }
                              name={"options_" + key}
                            />
                          )}
                        </InputGroup.Text>
                      </InputGroup.Prepend>

                      <FormControl
                        placeholder="Option"
                        name={options[key]}
                        value={options[key][k]}
                        id={k}
                        onChange={(e) => handleOptions(e, key, k)}
                        required={true}
                      />
                      <InputGroup.Append
                        onClick={() => removeOption(key, k)}
                        className="pointer"
                      >
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faTimes} />
                        </InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                  </>
                ))}
                {/* {options.map((option, k) => (
                            <>
                              <InputGroup className="mb-1" key={k}>
                                <InputGroup.Prepend>
                                  <InputGroup.Text>
                                    {options[k] && (
                                      <Form.Check
                                        name={"options_" + key}
                                        type={!isMultiple ? "radio" : "checkbox"}
                                        onChange={(e) => {
                                          setCorrectOptionsIndex((prev) => {
                                            const updatedState = produce(
                                              prev,
                                              (draft) => {
                                                if (isMultiple) {
                                                  if (prev.includes(k)) {
                                                    const index = draft.findIndex(
                                                      (el) => el === k
                                                    );
                                                    draft.splice(index, 1);
                                                  } else {
                                                    draft.push(k);
                                                  }
                                                } else {
                                                  draft.splice(0, draft.length);
                                                  draft.push(k);
                                                }
                                              }
                                            );
                                            return updatedState;
                                          });
                                        }}
                                        checked={correctOptionsIndex.includes(k)}
                                      />
                                    )}
                                  </InputGroup.Text>
                                </InputGroup.Prepend>
  
                                <FormControl
                                  placeholder="Option"
                                  id={k}
                                  required
                                  onChange={(e) => {
                                    setOptions((prev) => {
                                      const updatedState = produce(
                                        prev,
                                        (draft) => {
                                          draft[k][
                                            Math.floor(
                                              100000 + Math.random() * 900000
                                            )
                                          ] = {
                                            optionImage: null,
                                            // correctAnswer: correctOptionsIndex.includes(
                                            //   index
                                            // ),
                                            optionValue: e.target.value,
                                            // sequenceNo: index + 1,
                                          };
                                        }
                                      );
                                      return updatedState;
                                    });
                                  }}
                                />
                                <InputGroup.Append>
                                  <InputGroup.Text
                                    className="pointer"
                                    onClick={() =>
                                      setOptions((prev) => {
                                        const updatedState = produce(
                                          prev,
                                          (draft) => {
                                            draft.splice(key, 1);
                                          }
                                        );
                                        return updatedState;
                                      })
                                    }
                                  >
                                    <FontAwesomeIcon icon={faTimes} />
                                  </InputGroup.Text>
                                </InputGroup.Append>
                              </InputGroup>
                            </>
                          ))} */}
                <InputGroup className="w-100 mt-2">
                  <Button
                    size="sm"
                    className="w-100"
                    onClick={() => {
                      addOption(key);
                    }}
                  >
                    + <small>Add Option</small>
                  </Button>
                </InputGroup>
              </Card.Body>
            </Card>
          ))}

        <InputGroup className="w-100 mb-2">
          <Button
            size="sm"
            variant="outline-primary"
            className="w-100"
            onClick={() => {
              addQuestion();
            }}
          >
            + Add Question
          </Button>
        </InputGroup>

        {isEditting ? (
          <Button
            className="w-100"
            variant="primary"
            size="sm"
            disabled={saving}
            onClick={() => {
              editQuiz();
            }}
          >
            {saving ? (
              <>
                <Spinner size="sm" animation="border" color="white" /> Saving
              </>
            ) : (
              "Save Changes"
            )}
          </Button>
        ) : (
          <Button
            className="w-100"
            variant="primary"
            size="sm"
            disabled={saving}
            onClick={() => {
              addQuiz();
            }}
          >
            {saving ? (
              <>
                <Spinner size="sm" animation="border" color="white" />{" "}
                Submitting...
              </>
            ) : (
              "Submit"
            )}
          </Button>
        )}
      </div>
    </Form>
  );
};
const mapStateToProps = (state) => ({
  eventId: state.eventId,
  currentEvent: state.currentEvent,
});
export default memo(connect(mapStateToProps)(Create));
