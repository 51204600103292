import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Row,
  Nav,
  Button,
  Form,
  Card,
  Dropdown,
  Table,
  Modal,
  Spinner,
  FormCheck,
} from "react-bootstrap";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faVideo,
  faCalendarAlt,
  faTicketAlt,
  faClipboardList,
  faEdit,
  faCheck,
  faTimes,
  faLink,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import firebase from "firebase";
import AddToCalendar from "@culturehq/add-to-calendar";
import "@culturehq/add-to-calendar/dist/styles.css";
import axios from "axios";
import parse from "html-react-parser";
import moment from "moment";
import Select from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import _ from "lodash";
import { useHistory, useLocation } from "react-router";
import produce from "immer";
import { connect } from "react-redux";
import { changeLanguage } from "../../../store/actions/actions";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import "@culturehq/add-to-calendar/dist/styles.css";
import Category from "./Category";
import DraggableList from "./DraggableList";
import FormFileInput from "react-bootstrap/esm/FormFileInput";
import {
  API_BASE_URL,
  DRIVE_UPLOAD,
  ADMIN_CHECK_LINKING,
  ADMIN_DELETE_LINKING,
} from "../../../config/index";

toast.configure();

const Schedule = (props) => {
  // validations
  const currentUser = firebase.auth().currentUser;

  const { currentLanguage, changeLanguage, darkTheme, eventId } = props;

  const [showAddScheduleModal, setShowAddScheduleModal] = useState(false);
  const [editValuesCategory, setEditValuesCategory] = useState({});
  const [showDeleteCategoryModal, setShowDeleteCategoryModal] = useState(false);
  const [deleteCategoryId, setDeleteCategoryId] = useState(null);

  const [meetingType, setMeetingType] = useState(0);
  const [meetingUrl, setMeetingUrl] = useState(null);
  const [meetingStartUrl, setMeetingStartUrl] = useState(null);

  const [liveStreaming, setLiveStreaming] = useState(false);
  const [streamingLink, setStreamingLink] = useState("");
  const [isLinkedWith, setIsLinkedWith] = useState(false);
  const [linkedWith, setLinkedWith] = useState([]);
  const [checkingLinking, setCheckingLinking] = useState(true);

  const [eventSnapshot, loadingEvent, errorEvent] = useDocument(
    firebase.firestore().collection("events").doc(eventId)
  );
  const api_url =
    eventSnapshot?.data()?.api_type === "prod"
      ? `api.igesia.com`
      : `dev.igesia.com`;

  const SCHEDULE_MEETING =
    api_url === "dev.igesia.com"
      ? `https://dev.igesia.com/integration/zoom/meetings/schedule`
      : `https://${api_url}/events/meetings/schedule`;

  const [scheduleSnapshot, loading, error] = useCollection(
    firebase
      .firestore()
      .collection(`/events/${eventId}/schedule`)
      .orderBy("starts_at", "asc")
  );

  useEffect(() => {
    if (scheduleSnapshot) {
      if (scheduleSnapshot?.docs?.length != 0) {
        firebase
          .firestore()
          .collection("events")
          .doc(eventId)
          .update({ agenda_available: true });
      } else {
        firebase
          .firestore()
          .collection("events")
          .doc(eventId)
          .update({ agenda_available: false });
      }
    }
  }, [scheduleSnapshot]);

  const [speakersSnapshot, loadingSpeakers, errorSpeakers] = useCollection(
    firebase.firestore().collection(`/events/${eventId}/speakers`)
  );

  const [attendeeSnapshot, loadingattendees, errorattendees] = useCollection(
    firebase
      .firestore()
      .collection(`/events/${eventId}/users`)
      .orderBy("firstName")
  );

  const [
    attendeeCategorySnapshot,
    loadingattendeesCategory,
    errorattendeesCategory,
  ] = useCollection(
    firebase.firestore().collection(`/events/${eventId}/attendee_category`)
  );

  const [stageSnapshot, loadingStage, errorStage] = useCollection(
    firebase.firestore().collection(`/events/${eventId}/stages`)
  );
  const [categorySnapshot, loadingCategory, errorCategory] = useCollection(
    firebase
      .firestore()
      .collection(`/events/${eventId}/categories`)
      .orderBy("order_index", "asc")
  );

  const [showAttendee, setShowAttendee] = useState(false);

  const sortedCategories = categorySnapshot?.docs.map((category) => ({
    ...category.data(),
    id: category.id,
  }));

  const [saving, setSaving] = useState(false);
  const [showAddCategory, setShowAddCategory] = useState(false);
  const [showReorderCategory, setShowReorderCategory] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [deleteWarningModal, setDeleteWarningModal] = useState(false);
  const [selectedUID, setSelectedUID] = useState(null);
  const [selectedMeetingID, setSelectedMeetingID] = useState("");
  const [selectedMeetingData, setSelectedMeetingData] = useState(null);

  const [assignedToCategoryMultiple, setAssignedToCategoryMultiple] =
    useState(false);

  const stageOptions =
    stageSnapshot?.docs.map((stage) => ({
      value: stage.id,
      label: stage.data().stage_name,
      slug: stage.data().slug,
    })) ?? [];

  const categoryOptions = categorySnapshot?.docs.map((category) => ({
    value: category.id,
    label: category.data().title,
  }));

  const [scheduleData, setScheduleData] = useState({
    assignedToAll: true,
  });

  useEffect(() => {
    if (scheduleData?.assignedToAll) {
      setShowAttendee(false);
    } else if (!scheduleData?.assignedToAll) {
      setShowAttendee(true);
    }

    setMeetingType(scheduleData?.meeting_type ?? 0);
    setMeetingUrl(scheduleData?.meeting_url);
    setMeetingStartUrl(scheduleData?.start_url);

    if (parseInt(scheduleData?.meeting_type) === 2) {
      setLiveStreaming(scheduleData?.live_streaming);
      setStreamingLink(scheduleData?.streaming_link);
    }
  }, [showAddScheduleModal]);

  const saveTextDataToStateHandler = (e, lang) => {
    setScheduleData((prev) => {
      const updatedState = produce(prev, (draft) => {
        draft[lang] = {
          ...prev[lang],
          [e.target.name]: e.target.value
            .replace(/<p>/g, "")
            .replace(/<\/p>/g, ""),
        };
      });
      return updatedState;
    });
  };

  const schedule_category_enabled =
    eventSnapshot?.data().schedule_category_enabled ?? false;

  const schedule_category_layout =
    eventSnapshot?.data().schedule_category_layout ?? false;

  const saveDateToStateHandler = (selectedDate, dateStr, instance) => {
    setScheduleData((prev) => {
      return { ...prev, [instance.input.name]: selectedDate[0].valueOf() };
    });
  };

  const saveCheckBoxToStateHandler = (e) => {
    setScheduleData((prev) => {
      return { ...prev, [e.target.name]: e.target.checked ? true : false };
    });
  };

  const saveFileToStateHandler = (e) => {
    setScheduleData((prev) => {
      return { ...prev, [e.target.name]: e.target.files[0] };
    });
  };

  let getRoundedDate = (minutes, d = new Date()) => {
    let ms = 1000 * 60 * minutes; // convert minutes to ms
    let roundedDate = new Date(Math.round(d.getTime() / ms) * ms);

    return roundedDate;
  };

  const validateScheduleData = (schedule) => {
    if (!schedule.title.trim().length) {
    }
  };

  const createZoomMeetingHandler = async (meetingData) => {
    const response = await axios.post(SCHEDULE_MEETING, {
      user_id: eventSnapshot?.data()?.created_by ?? "unauthorised",
      event_id: eventId,
      meeting_topic: meetingData["EN"].title,
      meeting_agenda:
        meetingData["EN"]?.description
          ?.replace(/<p>/g, "")
          .replace(/<\/p>/g, "") ?? " ",
      meeting_timezone: "Asia/Calcutta",
      meeting_start_time: moment(meetingData.starts_at).format(),
      meeting_duration: Math.floor(
        ((meetingData.ends_at - meetingData.starts_at) / 1000 / 60) << 0
      ),
    });
    return response;
  };

  const uploadFile = async (file) => {
    if (file.name) {
      const fileData = new FormData();

      fileData.append("file", file, `${file.lastModified}-${file.name}`);
      fileData.append(
        "user_id",
        currentUser?.uid ?? "1gHTSDwzWRPW7KAUeTcscDFxs7z2"
      );
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
        // onUploadProgress: (ProgressEvent) => {
        //   setState({
        //     percentUploaded: (ProgressEvent.loaded * 100) / ProgressEvent.total,
        //   });
        // },
      };
      if (file) {
        try {
          let res = await axios.post(
            `${API_BASE_URL}${DRIVE_UPLOAD}`,
            fileData,
            config
          );
          return res.data.data["file_url"];
        } catch (error) {
          console.error(error, "error in writing file to db");
        }
      } else return "";
    } else {
      return file;
    }
  };

  const getWhetherURLOrLocalFile = (file) => {
    if (!file.name) {
      return file;
    } else {
      return URL.createObjectURL(file);
    }
  };

  const addScheduleToDBHandler = async (schedule) => {
    const schedule_background = schedule.schedule_background
      ? await uploadFile(schedule.schedule_background)
      : null;

    try {
      setSaving(true);

      let docref = firebase
        .firestore()
        .collection(`/events/${eventId}/schedule`)
        .doc();

      // Meeting type: Zoom
      if (meetingType === 0) {
        const response = await createZoomMeetingHandler(schedule);

        if (response.status === 200) {
          // const meetingURL = "https://zoom.us/j/" + response.data.data.meeting_id;
          const { start_url, join_url, meeting_id, meeting_password, state } =
            response.data.data;
          const scheduleWithoutTranslations = { ...schedule };
          delete scheduleWithoutTranslations.EN;
          delete scheduleWithoutTranslations.FR;

          const new_schedule_data = {
            EN: {
              ...schedule["EN"],
              description: schedule["EN"].description ?? " ",
            },
            FR: {
              ...schedule["FR"],
              // description: `<p>${schedule["FR"].description}</p>`,
            },
            ...scheduleWithoutTranslations,
            created_at: new Date().valueOf(),
            meeting_url: join_url,
            start_url: start_url,
            meeting_id: meeting_id,
            meeting_password: meeting_password ?? "",
            state: state ?? "",
            schedule_background,
            meeting_type: parseInt(meetingType) ?? 0,
          };

          await docref.set(new_schedule_data, { merge: true });
        }

        if (schedule.livestream) {
          const { state } = response.data.data;

          const responseOfStreamCreation = await axios.post(
            "https://api2.igesia.com/streams",
            {
              stream_title: schedule["EN"].title,
              stream_description:
                schedule["EN"]?.description.replace(/(<([^>]+)>)/gi, " ") ??
                " ",
              stream_start_time: new Date(schedule.starts_at).toISOString(),
              stream_privacy_status: "unlisted",
            }
          );

          if (responseOfStreamCreation.status === 200) {
            const meetingURL =
              "https://zoom.us/j/" + response.data.data.meeting_id;
            const responseOfBind = await axios.post(
              "https://api2.igesia.com/streams/bind",
              {
                stream_title: schedule["EN"].title,
                stream_description:
                  schedule["EN"]?.description.replace(/(<([^>]+)>)/gi, " ") ??
                  " ",
                broadcast_id: responseOfStreamCreation.data.data.broadcast_id,
              }
            );
            docref
              .update({
                ...responseOfBind.data.data,
              })
              .then((response) => {
                toast.success("Schedule added successfully", {
                  position: toast.POSITION.TOP_CENTER,
                  hideProgressBar: true,
                  autoClose: 3000,
                });

                setShowAddScheduleModal(false);
                setScheduleData({});
              })
              .catch((error) => {
                console.error("Error adding schedule: ", error);
                toast.error("Error adding schedule", {
                  position: toast.POSITION.TOP_CENTER,
                  hideProgressBar: true,
                  autoClose: 3000,
                });
              })
              .finally(() => {
                setSaving(false);
              });

            const MEETING_LIVESTREAM =
              api_url === "dev.igesia.com"
                ? `https://dev.igesia.com/integration/zoom/meetings/${response.data.data.meeting_id}/livestream`
                : `https://${api_url}/events/meetings/${response.data.data.meeting_id}/livestream`;

            await axios.post(MEETING_LIVESTREAM, {
              stream_url:
                responseOfBind.data.data.streaming_server_config
                  .rtmp_ingestion_address,
              stream_key: responseOfBind.data.data.stream_key,
              video_link: responseOfBind.data.data.link,
              state: state ?? "",
            });
          }
        }
      }

      // Meeting type: Custom
      if (meetingType === 1) {
        if (
          (meetingStartUrl && meetingUrl) ||
          (liveStreaming && streamingLink)
        ) {
          const scheduleWithoutTranslations = { ...schedule };
          delete scheduleWithoutTranslations.EN;
          delete scheduleWithoutTranslations.FR;

          const new_schedule_data = {
            EN: {
              ...schedule["EN"],
              description: schedule["EN"].description ?? " ",
            },
            FR: {
              ...schedule["FR"],
              // description: `<p>${schedule["FR"].description}</p>`,
            },
            ...scheduleWithoutTranslations,
            created_at: new Date().valueOf(),
            meeting_url: meetingUrl,
            start_url: meetingStartUrl,
            meeting_type: parseInt(meetingType) ?? 0,
            live_streaming: liveStreaming,
            streaming_link: streamingLink,
            schedule_background,
          };

          docref
            .set(new_schedule_data, { merge: true })
            .then((response) => {
              toast.success("Schedule added successfully", {
                position: toast.POSITION.TOP_CENTER,
                hideProgressBar: true,
                autoClose: 3000,
              });

              setShowAddScheduleModal(false);
              setScheduleData({});
            })
            .catch((error) => {
              console.error("Error adding schedule: ", error);
              toast.error("Error adding schedule", {
                position: toast.POSITION.TOP_CENTER,
                hideProgressBar: true,
                autoClose: 3000,
              });
            })
            .finally(() => {
              setSaving(false);
            });
        } else {
          toast.warning("Please enter mandatory fields!", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
          setSaving(false);
        }
      }

      // Meeting type: Igesia
      if (meetingType === 2) {
        if (liveStreaming && streamingLink) {
          const scheduleWithoutTranslations = { ...schedule };
          delete scheduleWithoutTranslations.EN;
          delete scheduleWithoutTranslations.FR;

          const new_schedule_data = {
            EN: {
              ...schedule["EN"],
              description: schedule["EN"].description ?? " ",
            },
            FR: {
              ...schedule["FR"],
              // description: `<p>${schedule["FR"].description}</p>`,
            },
            ...scheduleWithoutTranslations,
            created_at: new Date().valueOf(),
            meeting_type: parseInt(meetingType),
            live_streaming: liveStreaming,
            streaming_link: streamingLink,
            schedule_background,
          };

          docref
            .set(new_schedule_data, { merge: true })
            .then((response) => {
              toast.success("Schedule added successfully", {
                position: toast.POSITION.TOP_CENTER,
                hideProgressBar: true,
                autoClose: 3000,
              });

              setShowAddScheduleModal(false);
              setScheduleData({});
            })
            .catch((error) => {
              console.error("Error adding schedule: ", error);
              toast.error("Error adding schedule", {
                position: toast.POSITION.TOP_CENTER,
                hideProgressBar: true,
                autoClose: 3000,
              });
            })
            .finally(() => {
              setSaving(false);
            });
        } else if (!liveStreaming) {
          const scheduleWithoutTranslations = { ...schedule };
          delete scheduleWithoutTranslations.EN;
          delete scheduleWithoutTranslations.FR;

          const new_schedule_data = {
            EN: {
              ...schedule["EN"],
              description: schedule["EN"].description ?? " ",
            },
            FR: {
              ...schedule["FR"],
              // description: `<p>${schedule["FR"].description}</p>`,
            },
            ...scheduleWithoutTranslations,
            created_at: new Date().valueOf(),
            meeting_type: parseInt(meetingType),
            schedule_background,
          };

          docref
            .set(new_schedule_data, { merge: true })
            .then((response) => {
              toast.success("Schedule added successfully", {
                position: toast.POSITION.TOP_CENTER,
                hideProgressBar: true,
                autoClose: 3000,
              });

              setShowAddScheduleModal(false);
              setScheduleData({});
            })
            .catch((error) => {
              console.error("Error adding schedule: ", error);
              toast.error("Error adding schedule", {
                position: toast.POSITION.TOP_CENTER,
                hideProgressBar: true,
                autoClose: 3000,
              });
            })
            .finally(() => {
              setSaving(false);
            });
        } else {
          toast.warning("Please enter mandatory fields!", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
          setSaving(false);
        }
      }
    } catch (e) {
      console.error(e);
      toast.error("Error adding schedule", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
    }
    //  finally {
    //   setSaving(false);
    //   setShowAddScheduleModal(false);
    //   setScheduleData({});
    // }
  };

  const updateScheduleToDBHandler = async (schedule) => {
    const schedule_background = schedule.schedule_background
      ? await uploadFile(schedule.schedule_background)
      : null;

    try {
      setSaving(true);
      let docref = firebase
        .firestore()
        .collection(`/events/${eventId}/schedule`)
        .doc(schedule.id);

      const scheduleWithoutTranslations = { ...schedule };
      delete scheduleWithoutTranslations.EN;
      delete scheduleWithoutTranslations.FR;

      const updated_schedule_data =
        meetingType === 0
          ? {
              EN: {
                ...schedule["EN"],
                description: `${schedule["EN"].description}`,
              },
              FR: {
                ...schedule["FR"],
                //description: `<p>${schedule["FR"].description}</p>`,
              },
              ...scheduleWithoutTranslations,
              meeting_type: 0,
              updated_at: new Date().valueOf(),
              schedule_background,
            }
          : meetingType === 1
          ? {
              EN: {
                ...schedule["EN"],
                description: `${schedule["EN"].description}`,
              },
              FR: {
                ...schedule["FR"],
                //description: `<p>${schedule["FR"].description}</p>`,
              },
              ...scheduleWithoutTranslations,
              meeting_url: meetingUrl,
              start_url: meetingStartUrl,
              meeting_type: 1,
              live_streaming: liveStreaming,
              streaming_link: liveStreaming ? streamingLink : null,
              updated_at: new Date().valueOf(),
              schedule_background,
            }
          : meetingType === 2
          ? {
              EN: {
                ...schedule["EN"],
                description: `${schedule["EN"].description}`,
              },
              FR: {
                ...schedule["FR"],
                //description: `<p>${schedule["FR"].description}</p>`,
              },
              ...scheduleWithoutTranslations,
              meeting_type: 2,
              live_streaming: liveStreaming,
              streaming_link: liveStreaming ? streamingLink : null,
              updated_at: new Date().valueOf(),
              schedule_background,
            }
          : {
              EN: {
                ...schedule["EN"],
                description: `${schedule["EN"].description}`,
              },
              FR: {
                ...schedule["FR"],
                //description: `<p>${schedule["FR"].description}</p>`,
              },
              ...scheduleWithoutTranslations,
              updated_at: new Date().valueOf(),
              schedule_background,
            };

      await docref.update(updated_schedule_data);

      toast.success("Schedule updated successfully", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
      setScheduleData({});
    } catch (e) {
      console.error(e);
      toast.error("Error adding schedule", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
    } finally {
      setIsUpdating(false);
      setSaving(false);
      setShowAddScheduleModal(false);
      setScheduleData({});
    }
  };

  const speakerOptions = speakersSnapshot?.docs.map((speaker) => {
    if (speaker.data().email_address) {
      return {
        value: speaker.id,
        label: `${speaker.data().first_name} ${speaker.data().last_name}`,
      };
    } else {
      return { value: speaker.id, label: speaker.data().name };
    }
  });

  const attendeesCategoryOptions = attendeeCategorySnapshot?.docs.map(
    (category) => {
      return {
        value: category?.id,
        label: `${category.data().name}`,
      };
    }
  );

  const attendeesOptions = attendeeSnapshot?.docs.map((attendee) => {
    if (attendee.data().email) {
      return {
        value: attendee.id,
        label: `${attendee.data().firstName} ${attendee.data().lastName}`,
      };
    } else {
      return { value: attendee.id, label: attendee.data().name };
    }
  });

  const handleDeleteLinking = async (data) => {
    try {
      console.log("[Try] Deleting linking: ", data);

      const response = await axios.post(
        `${API_BASE_URL}${ADMIN_DELETE_LINKING}`,
        data
      );

      if (response.status === 200) {
        console.log("[Success] Deleting linking: ", response.data);
      }
    } catch (error) {
      console.log("[Error] Deleting linking: ", error);
    }
  };

  const removeScheduleHandler = async (uid) => {
    try {
      await firebase
        .firestore()
        .collection(`/events/${eventId}/schedule`)
        .doc(uid)
        .delete();

      if (isLinkedWith) {
        handleDeleteLinking({
          event_id: eventId,
          user_id: currentUser.uid,
          content_id: uid,
          type: "schedule",
        });
      }

      if (selectedMeetingID) {
        const MEETING_DELETE =
          api_url === "dev.igesia.com"
            ? `https://dev.igesia.com/integration/zoom/meetings/${selectedMeetingID}`
            : `https://${api_url}/events/meetings/${selectedMeetingID}`;

        var data = JSON.stringify({ state: selectedMeetingData?.state ?? "" });

        var config = {
          method: "delete",
          url: MEETING_DELETE,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };

        await axios(config);
      }

      toast.dark("Schedule deleted successfully", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
    } catch (e) {
      console.error(e, "error in deleting");
    }
  };

  // reoder category
  // throttling
  const listReorderHandler = (newOrder) => {
    // new order will be an array of indices
    newOrder.forEach((order, index) => {
      // [1,0,3,2]
      if ((order !== undefined || order !== null) && sortedCategories.length) {
        sortedCategories[order].order_index = index;
      }
    });
  };
  const saveOrderIndexToDBHandler = async () => {
    try {
      sortedCategories.forEach(async (category) => {
        await firebase
          .firestore()
          .collection(`/events/${eventId}/categories`)
          .doc(category.id)
          .update({
            order_index: category.order_index,
          });
      });
    } catch (e) {
      console.error(e, "error in changing the order");
    }
  };

  const throttledReorder = _.throttle(listReorderHandler, 1000);

  // category navbar
  const [currentIndex, setCurrentIndex] = useState(1001);
  const history = useHistory();

  // speakers, category, stage name handler
  const nameHandler = (type, id) => {
    const arrayToTraverse =
      type === "speakers"
        ? speakerOptions
        : type === "category"
        ? categoryOptions
        : type === "stage"
        ? stageOptions
        : (type = "attendees" ? attendeesOptions : []);
    if (arrayToTraverse?.length) {
      return arrayToTraverse.find((element) => element.value === id);
    }
  };

  const getScheduleValueHandler = () => {
    if (scheduleData?.stage?.value) {
      return stageOptions.findIndex(
        (stage) => stage.value === scheduleData.stage.value
      );
    } else if (scheduleData?.stage?.stage_id) {
      return stageOptions.findIndex(
        (stage) => stage.value === scheduleData.stage.stage_id
      );
    }
  };

  const getCategoryHandler = () => {
    if (scheduleData?.category?.trim().length) {
      return categoryOptions.findIndex(
        (category) => category.value === scheduleData.category
      );
    }
  };

  const getSpeakerHandler = () => {
    if (scheduleData?.speakers?.length) {
      let data = speakerOptions.map((item) => {
        if (scheduleData?.speakers.includes(item.value)) return item;
      });
      return data;
    }
  };

  const getAttendeesCategoryHandler = () => {
    if (scheduleData?.assignedToCategory?.length) {
      let data = attendeesCategoryOptions.map((item) => {
        if (scheduleData?.assignedToCategory.includes(item.value)) {
          return item;
        }
      });
      return data;
    }
  };

  const getAttendeesHandler = () => {
    if (scheduleData?.assignedTo?.length) {
      let data = attendeesOptions.map((item) => {
        if (scheduleData?.assignedTo.includes(item.value)) {
          return item;
        } else return;
      });
      return data;
    }
  };

  const handleDeleteScheduleCategory = (category_Id) => {
    let result = scheduleSnapshot?.docs.filter(
      (item) => item?.data()?.category === category_Id
    );

    if (result?.length === 0) {
      firebase
        .firestore()
        .collection(`events/${eventId}/categories`)
        .doc(`${category_Id}`)
        .delete()
        .then(() => {
          toast.success("Schedule category deleted", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
        })
        .catch((err) => {
          console.error("Error deleting schedule category", err);
          toast.error("Fail to delete schedule category", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
        });
    } else {
      toast.error("Category is assigned to some schedule", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
    }
  };

  const timeValidation = (minMilliseconds) => {
    if (
      scheduleData.ends_at - scheduleData.starts_at < 0 ||
      scheduleData.ends_at - scheduleData.starts_at < minMilliseconds
    ) {
      return 0;
    } else {
      return 1;
    }
  };

  const handleCheckLinking = async (data) => {
    try {
      console.log("[Try] Cheking link: ", data);

      const response = await axios.post(
        `${API_BASE_URL}${ADMIN_CHECK_LINKING}`,
        data
      );

      if (response.status === 200) {
        const { data } = response.data;
        console.log("[Success] Checking link: ", data);

        if (data?.length > 0) {
          setIsLinkedWith(true);
          setLinkedWith(data);
        } else {
          setIsLinkedWith(false);
        }
        setCheckingLinking(false);
      }
    } catch (error) {
      console.log("[Error] Checking link: ", error);
      setCheckingLinking(false);
    }
  };

  const ScheduleListElement = ({ idx, item, scheduleLangData }) => {
    return (
      <div className="col-md-12 p-3 border border-dark rounded mb-3">
        <div className="d-flex justify-content-between small">
          <div>
            {moment(item.data().starts_at).format("LL")},
            {moment(item.data().starts_at).format("LT")} -{" "}
            {moment(item.data().ends_at).format("LT")}
          </div>
          <div>{parseInt(idx) + 1}</div>
        </div>
        <h5>{scheduleLangData.title}</h5>
        <div className="small">
          {nameHandler("category", item.data()?.category)?.label} •{" "}
          {nameHandler("stage", item.data()?.stage?.stage_id)?.label}
        </div>

        <div>
          <span className="mb-3 mr-2 small font-weight-bold">Speakers</span>
          {item.data()?.speakers?.map((speaker) => {
            return (
              <span className="mb-0 mr-2 small">
                {nameHandler("speakers", speaker)?.label}
              </span>
            );
          })}
        </div>

        <div className="small mb-3">
          {/* {parse(scheduleLangData.description)} */}
          {scheduleLangData.description.replace(/<[^>]*>?/gm, " ").length > 250
            ? scheduleLangData.description
                .replace(/<[^>]*>?/gm, " ")
                .substring(0, 250) + "..."
            : scheduleLangData.description.replace(/<[^>]*>?/gm, " ")}
        </div>

        <div className="small mb-3">
          {item.data().meeting_type === 1 || item.data().meeting_type === 2 ? (
            item.data().live_streaming ? (
              <span className="mr-2">
                <a href={item.data()?.streaming_link} target="_blank">
                  <FontAwesomeIcon
                    icon={faCheck}
                    className="text-success mr-1"
                  />
                  <span>Live Streaming</span>
                </a>
              </span>
            ) : (
              <span className="mr-2">
                <FontAwesomeIcon icon={faTimes} className="text-danger mr-1" />
                <span>No Live Stream</span>
              </span>
            )
          ) : item.data()?.livestream ? (
            item.data()?.link ? (
              <span className="mr-2">
                <a href={item.data()?.link} target="_blank">
                  <FontAwesomeIcon
                    icon={faCheck}
                    className="text-success mr-1"
                  />
                  <span>YouTube</span>
                </a>
              </span>
            ) : (
              <span
                className="mr-2"
                style={{ cursor: "default" }}
                title="Quick fix: Re-create session for proper YouTube integration"
              >
                <FontAwesomeIcon icon={faTimes} className="text-danger mr-1" />
                <span>Error in Live stream</span>
              </span>
            )
          ) : (
            <span className="mr-2">
              <FontAwesomeIcon icon={faTimes} className="text-danger mr-1" />
              <span>No Live Stream</span>
            </span>
          )}

          {/* Meeting type: Custom type & Zoom */}
          {item.data().meeting_type === 1 && item.data()?.meeting_url ? (
            <span className="mr-2">
              <a href={item.data()?.meeting_url} target="_blank">
                <FontAwesomeIcon icon={faVideo} className="text-primary mr-1" />
                <span>Custom video</span>
              </a>
            </span>
          ) : item.data()?.meeting_url ? (
            <span className="mr-2">
              <a href={item.data()?.meeting_url} target="_blank">
                <FontAwesomeIcon icon={faVideo} className="text-primary mr-1" />
                <span>Zoom video</span>
              </a>
            </span>
          ) : (
            ""
          )}

          {/* Meeting type: Igesia */}
          {item.data().meeting_type === 2 ? (
            <span className="mr-2">
              <FontAwesomeIcon icon={faVideo} className="text-primary mr-1" />
              <span>Igesia video</span>
            </span>
          ) : (
            ""
          )}

          {item.data()?.recording_link ? (
            <span className="mr-2">
              <a href={item.data()?.recording_link} target="_blank">
                <FontAwesomeIcon icon={faVideo} className="text-danger mr-1" />
                <span>Recording</span>
              </a>
            </span>
          ) : (
            ""
          )}
        </div>

        <div>
          {/* <div style={{ minWidth: "130px" }}>
                                <AddToCalendar
                                  event={{
                                    name: scheduleLangData.title,
                                    details: `${scheduleLangData.description
                                      }  <p><a href=${item.data().stage.stage_link
                                      }>${item.data().stage.stage_link}</a></p>`,
                                    startsAt: item.data().starts_at,
                                    endsAt: item.data().ends_at,
                                    location: item.data().location,
                                  }}
                                ></AddToCalendar>
                              </div> */}

          <Button
            className="btn-sm btn-light mr-2"
            onClick={() => {
              setIsUpdating(true);
              setScheduleData({
                ...item.data(),
                id: item.id,
              });
              console.log("Schedule data: ", {
                ...item.data(),
                id: item.id,
              });
              setShowAddScheduleModal(true);
              if (!item.data().assignedToAll) {
                setShowAttendee(true);
              }
            }}
          >
            Edit
          </Button>
          <Button
            onClick={() => {
              setSelectedMeetingID(
                item.data()?.meeting_id ??
                  item.data()?.meeting_url?.replace("https://zoom.us/j/", "")
              );
              setSelectedMeetingData(item.data());
              setSelectedUID(scheduleLangData.id);
              setDeleteWarningModal(true);

              handleCheckLinking({
                event_id: eventId,
                user_id: currentUser.uid,
                content_id: item?.id,
                type: "schedule",
              });
            }}
            className="btn-sm btn-danger"
            // disabled
          >
            Remove
          </Button>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="container-fluid text-light vh-100 pb-5">
        <div className="mt-3 overflow-auto ">
          <div className="h-100" style={{ overflow: "auto" }}>
            <h5>Live Sessions</h5>
            <div className="mt-3 pb-5">
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex">
                  <div className="d-flex flex-column mr-3">
                    <span className="text-secondary small font-weight-bold">
                      Show Category
                    </span>
                    <span>
                      <FormCheck
                        type="switch"
                        id="custom-switch"
                        label={schedule_category_enabled ? "On" : "Off"}
                        checked={schedule_category_enabled}
                        onChange={(e) =>
                          firebase
                            .firestore()
                            .collection("events")
                            .doc(eventId)
                            .update({
                              schedule_category_enabled: e.target.checked,
                            })
                        }
                      ></FormCheck>
                    </span>
                  </div>

                  <div className="d-flex flex-column">
                    <span className="text-secondary small font-weight-bold">
                      Modular category layout
                    </span>
                    <span>
                      <FormCheck
                        type="switch"
                        id="schedule_category_layout"
                        label={schedule_category_layout ? "On" : "Off"}
                        checked={schedule_category_layout}
                        onChange={(e) =>
                          firebase
                            .firestore()
                            .collection("events")
                            .doc(eventId)
                            .update({
                              schedule_category_layout: e.target.checked,
                            })
                        }
                      ></FormCheck>
                    </span>
                  </div>
                </div>

                <div className="d-flex">
                  <Button
                    className="btn-sm btn-primary mr-2"
                    onClick={() => setShowAddScheduleModal(true)}
                    // disabled={true}
                  >
                    + New schedule
                  </Button>
                  <Button
                    className="btn-sm btn-primary mr-2"
                    onClick={() => setShowAddCategory(true)}
                    // disabled={true}
                  >
                    + New category
                  </Button>
                  <Button
                    className="btn-sm btn-secondary"
                    onClick={() => setShowReorderCategory(true)}
                    // disabled={true}
                  >
                    Reorder category
                  </Button>
                </div>
              </div>

              <div className="w-100 d-flex justify-content-between mt-2 border-top border-bottom border-dark py-1">
                <span className="mr-2 d-inline-block mt-2 small">
                  Categories
                </span>
                <Nav
                  className="w-100 small"
                  size="sm"
                  defaultActiveKey={
                    categorySnapshot?.docs[currentIndex]?.data()?.title
                  }
                  activeKey={
                    categorySnapshot?.docs[currentIndex]?.data()?.title
                  }
                >
                  <Nav.Item>
                    <Nav.Link
                      className={
                        parseInt(currentIndex) === 1001
                          ? "border border-primary py-1"
                          : ""
                      }
                      eventKey="all"
                      onSelect={(e) => setCurrentIndex(1001)}
                    >
                      All
                    </Nav.Link>
                  </Nav.Item>

                  {categorySnapshot?.docs.map((category, index) => {
                    return (
                      <Nav.Item>
                        <Nav.Link
                          className={
                            parseInt(currentIndex) === index
                              ? "border border-primary py-1"
                              : ""
                          }
                          eventKey={category.data().title}
                          onSelect={(e) => setCurrentIndex(index)}
                        >
                          {category.data().title}
                        </Nav.Link>
                      </Nav.Item>
                    );
                  })}

                  <Nav.Item>
                    <Nav.Link
                      className={
                        parseInt(currentIndex) === 1000
                          ? "border border-primary py-1"
                          : ""
                      }
                      eventKey="uncategorised"
                      onSelect={(e) => setCurrentIndex(1000)}
                    >
                      Uncategorised
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>

              {
                <>
                  <div className="mt-3 mb-3 mb-md-4">
                    <h6 className="text-uppercase font-weight-bold">
                      Categories
                    </h6>
                    {loadingCategory ? (
                      <div className="d-flex align-items-center justify-content-center">
                        <Spinner animation="border" size="sm" />
                      </div>
                    ) : null}

                    {categorySnapshot?.docs?.length === 0 && (
                      <p className="text-center">No categories exists</p>
                    )}

                    {categorySnapshot?.docs?.map((item, idx) => (
                      <div
                        className={
                          darkTheme
                            ? "d-flex align-items-center mb-1 border border-secondary shadow-sm rounded-sm"
                            : "d-flex align-items-center mb-1 border shadow-sm rounded-sm"
                        }
                        key={idx}
                      >
                        <p className="mb-0 px-2 py-1">{idx + 1}.</p>
                        <p className="flex-grow-1 mb-0 mr-2 py-1">
                          {item.data().title}
                        </p>
                        <div>
                          <a
                            className="btn btn-sm pointer px-2 py-1"
                            onClick={() => {
                              setShowAddCategory(true);
                              setEditValuesCategory(item);
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faEdit}
                              className="text-white"
                            />
                          </a>
                          <a
                            className="btn btn-sm pointer px-2 py-1"
                            disabled={true}
                            onClick={() => {
                              setDeleteCategoryId(item.id);
                              setShowDeleteCategoryModal(true);
                              //handleDeleteScheduleCategory(item.id)
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faTimes}
                              className="text-white"
                            />
                          </a>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              }

              <div className="mt-3">
                <div className="row mx-0 mb-5">
                  {scheduleSnapshot?.docs.map((item, key) => {
                    const scheduleLangData = {
                      ...item.data()[currentLanguage],
                      id: item.id,
                    };
                    if (item.data().category) {
                      if (
                        categorySnapshot?.docs[currentIndex]?.id ==
                        item.data().category
                      ) {
                        return (
                          <ScheduleListElement
                            key={key}
                            idx={key}
                            item={item}
                            scheduleLangData={scheduleLangData}
                          />
                        );
                      }
                      if (currentIndex == 1001)
                        return (
                          <ScheduleListElement
                            key={key}
                            idx={key}
                            item={item}
                            scheduleLangData={scheduleLangData}
                          />
                        );
                    } else {
                      return (
                        <ScheduleListElement
                          key={key}
                          idx={key}
                          item={item}
                          scheduleLangData={scheduleLangData}
                        />
                      );
                    }
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showAddCategory ? (
        <Category
          show={showAddCategory}
          editValuesCategory={editValuesCategory}
          onHide={() => {
            setEditValuesCategory({});
            setShowAddCategory(false);
          }}
        />
      ) : null}

      {/* modal for add ticket */}

      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showAddScheduleModal}
        onHide={() => {
          setIsUpdating(false);
          setShowAddScheduleModal(false);
          setScheduleData({});
          setSaving(false);
        }}
      >
        <Modal.Header closeButton>
          <div>
            <Modal.Title id="contained-modal-title-vcenter">
              Add/ Update Schedule
            </Modal.Title>
            <p className="small text-muted mb-0">
              Marked fields (<span className="text-danger">*</span>) are
              mandatory
            </p>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="row">
              <Form.Group className="col-md-12">
                <Form.Label className="text-muted text-uppercase font-weight-bold small">
                  Title <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Title"
                  name="title"
                  value={scheduleData["EN"]?.title}
                  onChange={(e) => saveTextDataToStateHandler(e, "EN")}
                />
              </Form.Group>

              {/* <Form.Group className="col-md-12">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  row={1}
                  placeholder="Description"
                  value={scheduleData["EN"]?.description
                    ?.replace(/<p>/g, "")
                    ?.replace(/<\/p>/g, "")}
                  name="description"
                  onChange={(e) => saveTextDataToStateHandler(e, "EN")}
                />
              </Form.Group> */}

              <Form.Group className="col-md-12">
                <Form.Label>
                  <p className="text-muted text-uppercase font-weight-bold small mb-0">
                    Description <span className="text-danger">*</span>
                  </p>
                  <p className="small text-dark mb-0">
                    Recommdended: Min. 100 words (No Limit)
                  </p>
                </Form.Label>
                <CKEditor
                  id="editor"
                  editor={ClassicEditor}
                  config={{
                    toolbar: [
                      "heading",
                      "bold",
                      "italic",
                      "link",
                      "numberedList",
                      "bulletedList",
                      "undo",
                      "redo",
                    ],
                  }}
                  className="mb-3"
                  data={scheduleData["EN"]?.description ?? ""}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    // setSpeakerBio(data);

                    setScheduleData((prev) => {
                      const updatedState = produce(prev, (draft) => {
                        draft["EN"] = {
                          ...prev["EN"],
                          ["description"]: data,
                          // .replace(/<p>/g, "")
                          //.replace(/<\/p>/g, ""),
                        };
                      });
                      return updatedState;
                    });
                  }}
                  style={{ height: 200 }}
                />
              </Form.Group>

              {/*  // <Form.Group className="col-md-12">
              //   <Form.Label>Titre</Form.Label>
              //   <Form.Control
              //     type="text"
              //     placeholder="Titre"
              //     name="title"
              //     value={scheduleData["FR"]?.title}
              //     onChange={(e) => saveTextDataToStateHandler(e, "FR")}
              //   />
              // </Form.Group>
              // <Form.Group className="col-md-12">
              //   <Form.Label>La description</Form.Label>
              //   <Form.Control
              //     as="textarea"
              //     row={1}
              //     placeholder="La description"
              //     value={scheduleData["FR"]?.description
              //       ?.replace(/<p>/g, "")
              //       ?.replace(/<\/p>/g, "")}
              //     name="description"
              //     onChange={(e) => saveTextDataToStateHandler(e, "FR")}
              //   />
              // </Form.Group>
*/}
              {/* <Form.Group className="col-md-12">
                <Form.Label>
                  Stage <span className="text-muted">(optional)</span>
                </Form.Label>
                <Select
                  isSearchable
                  value={stageOptions[getScheduleValueHandler()]}
                  options={stageOptions}
                  onChange={(e) => {
                    setScheduleData((prev) => {
                      const prevScheduleData = {
                        ...prev,
                        stage: e,
                      };
                      return prevScheduleData;
                    });
                  }}
                />
              </Form.Group> */}

              <Form.Group className="col-md-12">
                <Form.Label className="text-muted text-uppercase font-weight-bold small">
                  Category <span className="text-muted">(optional)</span>
                </Form.Label>
                <Select
                  isSearchable
                  value={
                    categoryOptions?.length
                      ? categoryOptions[getCategoryHandler()]
                      : null
                  }
                  options={categoryOptions}
                  onChange={(e) => {
                    setScheduleData((prev) => {
                      const prevScheduleData = { ...prev, category: e.value };
                      return prevScheduleData;
                    });
                  }}
                />
              </Form.Group>

              <Form.Group className="col-md-6">
                <Form.Label className="text-muted text-uppercase font-weight-bold small">
                  Starts at <span className="text-danger">*</span>
                </Form.Label>
                <Flatpickr
                  placeholder="Select date"
                  data-enable-time
                  value={scheduleData.starts_at}
                  name="starts_at"
                  onChange={(selectedDate, dateStr, instance) =>
                    saveDateToStateHandler(selectedDate, dateStr, instance)
                  }
                  options={{ minuteIncrement: 5 }}
                />
              </Form.Group>

              <Form.Group className="col-md-6">
                <Form.Label className="text-muted text-uppercase font-weight-bold small">
                  Ends at <span className="text-danger">*</span>
                </Form.Label>
                <Flatpickr
                  placeholder="Select date"
                  data-enable-time
                  value={scheduleData.ends_at}
                  name="ends_at"
                  onChange={(selectedDate, dateStr, instance) =>
                    saveDateToStateHandler(selectedDate, dateStr, instance)
                  }
                  options={{ minuteIncrement: 5 }}
                />
              </Form.Group>

              <Form.Group className="col-md-12">
                <Form.Label className="text-muted text-uppercase font-weight-bold small">
                  Speakers <span className="text-muted">(optional)</span>
                </Form.Label>
                <Select
                  isMulti
                  isSearchable
                  options={speakerOptions}
                  value={speakerOptions?.length ? getSpeakerHandler() : null}
                  onChange={(e) => {
                    setScheduleData((prev) => {
                      const prevScheduleData = {
                        ...prev,
                        speakers: e.map((speaker) => speaker.value),
                      };
                      return prevScheduleData;
                    });
                  }}
                />
              </Form.Group>

              <Form.Group className="col-md-12">
                <Form.Label className="text-muted text-uppercase font-weight-bold small">
                  Attendees
                  {attendeesCategoryOptions?.length > 0 ? " Category" : ""}{" "}
                  <span className="text-danger">*</span>
                </Form.Label>
                <Form.Check
                  id="attendeeSelectall"
                  value="assignedToAll"
                  type="radio"
                  label="Assign to all"
                  name="attends"
                  checked={scheduleData.assignedToAll}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setShowAttendee(false);
                      setScheduleData((prev) => {
                        const prevScheduleData = {
                          ...prev,
                          assignedToAll: true,
                          assignedTo: [],
                        };
                        return prevScheduleData;
                      });
                    }
                  }}
                />

                <Form.Check
                  id="attendeeSelectspecific"
                  value="SendSpecific"
                  type="radio"
                  label={`Assign to specific ${
                    attendeesCategoryOptions?.length > 0 ? " category" : ""
                  }`}
                  name="attends"
                  checked={!scheduleData.assignedToAll}
                  onClick={(e) => {
                    if (e.target.checked) {
                      setShowAttendee(true);
                      setScheduleData((prev) => {
                        const prevScheduleData = {
                          ...prev,
                          assignedToAll: false,
                        };
                        return prevScheduleData;
                      });
                    }
                  }}
                />
                {showAttendee ? (
                  <Form.Check
                    id="attendeeSelectspecific"
                    value="SendSpecific"
                    type="checkbox"
                    label={`Assign to multiple category`}
                    name="attends"
                    checked={scheduleData?.assignedToCategoryMultiple}
                    onClick={() => {
                      setScheduleData((prev) => {
                        const prevScheduleData = {
                          ...prev,
                          assignedToCategoryMultiple:
                            !scheduleData?.assignedToCategoryMultiple,
                        };
                        return prevScheduleData;
                      });
                    }}
                  />
                ) : null}
                {showAttendee ? (
                  <Select
                    isMulti={scheduleData?.assignedToCategoryMultiple}
                    isSearchable
                    options={attendeesCategoryOptions}
                    // options={attendeesOptions}
                    // value={
                    //   attendeesOptions?.length >0
                    //     ? getAttendeesHandler()
                    //     : null
                    // }
                    value={
                      attendeesCategoryOptions?.length > 0
                        ? getAttendeesCategoryHandler()
                        : null
                    }
                    onChange={(e) => {
                      if (scheduleData?.assignedToCategoryMultiple) {
                        setScheduleData((prev) => {
                          const prevScheduleData = {
                            ...prev,
                            assignedToCategory: e.map(
                              (attendee) => attendee.value
                            ),
                            assignedToCategoryMultiple: true,
                            assignedToAll: false,
                          };
                          return prevScheduleData;
                        });
                      } else {
                        setScheduleData((prev) => {
                          const prevScheduleData = {
                            ...prev,
                            assignedToCategory: e.value,
                            assignedToAll: false,
                          };
                          return prevScheduleData;
                        });
                      }
                    }}
                  />
                ) : (
                  ""
                )}
              </Form.Group>

              <Form.Group className="col-md-12">
                <Form.Label className="text-muted text-uppercase font-weight-bold small">
                  Meeting type <span className="text-danger">*</span>
                </Form.Label>
                <div>
                  {["Zoom", "Custom", "Igesia"].map((type, key) => (
                    <Form.Check
                      disabled={isUpdating}
                      inline
                      label={type}
                      type={"radio"}
                      id={`meeting-type-${key}`}
                      key={`meeting-type-${key}`}
                      name={`meeting-type-${key}`}
                      checked={meetingType === key}
                      onClick={() => {
                        setMeetingType(key);
                      }}
                    />
                  ))}
                </div>
              </Form.Group>

              {isUpdating && (
                <Form.Group className="col-md-12">
                  <Form.Label className="text-muted text-uppercase font-weight-bold small">
                    Recording Link
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Recording Link"
                    name="recording_link"
                    value={scheduleData?.recording_link}
                    onChange={(e) => {
                      if (e.target.value.length > 0) {
                        setScheduleData((prev) => {
                          return {
                            ...prev,
                            recording_link: e.target.value,
                            is_ended: true,
                          };
                        });
                      } else {
                        setScheduleData((prev) => {
                          return {
                            ...prev,
                            recording_link: "",
                            is_ended: false,
                          };
                        });
                      }
                    }}
                  />
                  <Form.Text className="text-muted">
                    Recording will be visible to participants once added
                  </Form.Text>
                </Form.Group>
              )}

              {/* <Form.Group className="col-md-12">
                <Form.Label>Background</Form.Label>
                <FormFileInput
                  name="schedule_background"
                  onChange={(e) => saveFileToStateHandler(e)}
                ></FormFileInput>
              </Form.Group> */}

              {meetingType === 0 && (
                <Form.Group
                  className="col-md-12"
                  style={isUpdating ? { cursor: "not-allowed" } : {}}
                >
                  {scheduleData.livestream ? (
                    scheduleData.link ? (
                      <Form.Text>
                        <b>YouTube:</b>{" "}
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="text-success"
                        />{" "}
                        <a href={scheduleData.link} target="_blank">
                          {scheduleData.link}
                        </a>
                      </Form.Text>
                    ) : null
                  ) : null}

                  <Form.Check
                    id="livestream"
                    type="checkbox"
                    label="Live stream the session"
                    name="livestream"
                    value={scheduleData.livestream}
                    checked={scheduleData.livestream}
                    onChange={(e) => saveCheckBoxToStateHandler(e)}
                    disabled={isUpdating}
                  />
                  <Form.Text className="small text-muted">
                    <b>Note:</b> Live streaming cannot be added once a schedule
                    is created.
                  </Form.Text>
                </Form.Group>
              )}

              {meetingType === 1 && (
                <>
                  <Form.Group className="col-md-12">
                    <Form.Label className="text-muted text-uppercase font-weight-bold small">
                      Custom meeting join link{" "}
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      name="custom-meeting-join-link"
                      type="text"
                      placeholder="https://www.meeting-link.com"
                      onChange={(e) => setMeetingUrl(e.target.value)}
                      value={meetingUrl}
                    />
                    <Form.Text className="small text-muted">
                      <b>Note:</b> This link will be displayed to Participants
                      to join meeting.
                    </Form.Text>
                  </Form.Group>

                  <Form.Group className="col-md-12">
                    <Form.Label className="text-muted text-uppercase font-weight-bold small">
                      Custom meeting start link{" "}
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      name="custom-meeting-start-link"
                      type="text"
                      placeholder="https://www.meeting-link.com"
                      onChange={(e) => setMeetingStartUrl(e.target.value)}
                      value={meetingStartUrl}
                    />
                    <Form.Text className="small text-muted">
                      <b>Note:</b> This link will be displayed to Speakers /
                      Moderators to join meeting.
                    </Form.Text>
                  </Form.Group>
                </>
              )}

              {(meetingType === 1 || meetingType === 2) && (
                <>
                  <Form.Group className="col-md-12">
                    <Form.Check
                      id="liveStreaming"
                      type="checkbox"
                      label="Live streaming for the session"
                      name="liveStreaming"
                      value={liveStreaming}
                      checked={liveStreaming}
                      onChange={(e) => setLiveStreaming(e.target.checked)}
                    />
                    {meetingType === 2 && (
                      <Form.Text className="small text-muted">
                        <b>Note:</b> To start Live streaming moderator/ speaker
                        have to add "Streaming key" within session itself.
                      </Form.Text>
                    )}
                  </Form.Group>

                  {liveStreaming && (
                    <Form.Group className="col-md-12">
                      <Form.Label className="text-muted text-uppercase font-weight-bold small">
                        Streaming link <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        name="streaming_link"
                        type="text"
                        placeholder="https://www.streaming-url.com"
                        onChange={(e) => setStreamingLink(e.target.value)}
                        value={streamingLink}
                        required={liveStreaming}
                      />
                      <Form.Text className="small text-muted">
                        <b>Note:</b> Streaming will be displayed on Participant
                        dashboard.
                      </Form.Text>
                    </Form.Group>
                  )}
                </>
              )}

              {!isUpdating && (
                <p className="col-md-12 d-flex text-small">
                  {scheduleData.link}
                </p>
              )}
            </div>
            {!isUpdating ? (
              <Button
                variant="primary"
                className="w-100"
                disabled={
                  !scheduleData["EN"]?.description?.length ||
                  !scheduleData["EN"]?.title?.length ||
                  saving
                }
                onClick={() => {
                  if (timeValidation(5000)) {
                    addScheduleToDBHandler(scheduleData);
                  } else {
                    toast.error(
                      "Session end time should be at least 5 minutes from the start time",
                      {
                        position: toast.POSITION.TOP_CENTER,
                        hideProgressBar: true,
                        autoClose: 3000,
                      }
                    );
                  }
                }}
                //disabled={saving}
              >
                {saving ? <Spinner animation="border" size="sm" /> : "Save"}
              </Button>
            ) : (
              <Button
                variant="primary"
                className="w-100"
                onClick={() => {
                  if (timeValidation(5000)) {
                    updateScheduleToDBHandler(scheduleData);
                  } else {
                    toast.error(
                      "Session end time should be at least 5 minutes from the start time",
                      {
                        position: toast.POSITION.TOP_CENTER,
                        hideProgressBar: true,
                        autoClose: 3000,
                      }
                    );
                  }
                }}
                disabled={
                  !scheduleData["EN"]?.description?.length ||
                  !scheduleData["EN"]?.title?.length ||
                  saving
                }
              >
                {saving ? <Spinner animation="border" size="sm" /> : "Update"}
              </Button>
            )}
          </Form>
        </Modal.Body>
      </Modal>

      {/* reorder category */}
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showReorderCategory}
        onHide={() => setShowReorderCategory(false)}
      >
        <Modal.Header closeButton>Reorder categories</Modal.Header>
        <Modal.Body>
          <DraggableList
            orderFunction={throttledReorder}
            items={sortedCategories}
            variant="categories"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            className="btn-sm"
            onClick={() => setShowReorderCategory(false)}
          >
            Cancel
          </Button>
          <Button
            variant="success"
            className="btn-sm"
            onClick={() => {
              saveOrderIndexToDBHandler();
              setShowReorderCategory(false);
            }}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      {/* delete warning */}
      <Modal
        show={deleteWarningModal}
        onHide={() => {
          setSelectedUID("");
          setDeleteWarningModal(false);
        }}
        centered
      >
        <Modal.Header closeButton>
          <div>
            <h5 className="mb-1">{selectedMeetingData?.EN?.title}</h5>
            <p className="small mb-0 text-muted text-uppercase">
              Confirm deletion
            </p>
          </div>
        </Modal.Header>
        <Modal.Body>
          <p className="lead">
            Are you sure you want to delete? Delete operation cannot be
            reversed.
          </p>
          {!checkingLinking && isLinkedWith && (
            <p>
              <b>Note: </b>Looks like <b>{selectedMeetingData?.EN?.title}</b> is
              added to <b>Modules</b>, if you delete it will also remove them
              from modules.
            </p>
          )}
          <div className="d-flex justify-content-end">
            <Button
              variant="light"
              className="btn-sm mr-2 rounded-lg text-uppercase border border-muted"
              onClick={() => setDeleteWarningModal(false)}
              disabled={checkingLinking}
            >
              Cancel
            </Button>
            <Button
              variant="danger"
              className="btn-sm rounded-lg text-uppercase"
              onClick={() => {
                removeScheduleHandler(selectedUID);
                setDeleteWarningModal(false);
              }}
              disabled={checkingLinking}
            >
              delete
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      {/* delete schedule category warning */}
      <Modal
        show={showDeleteCategoryModal}
        onHide={() => {
          setShowDeleteCategoryModal(false);
        }}
        centered
      >
        <Modal.Header closeButton>Confirm deletion</Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete? This action cannot be reversed</p>
          {/* <p>Contine ?</p> */}
          <div className="d-flex justify-content-end">
            <Button
              variant="secondary"
              className="btn-sm mr-3"
              onClick={() => setShowDeleteCategoryModal(false)}
            >
              Cancel
            </Button>
            <Button
              variant="danger"
              className="btn-sm"
              onClick={() => {
                handleDeleteScheduleCategory(deleteCategoryId);
                setShowDeleteCategoryModal(false);
              }}
            >
              Yes, delete
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default connect(
  (state) => ({
    currentLanguage: state.currentLanguage,
    eventId: state.eventId,
    darkTheme: state.darkTheme,
  }),
  { changeLanguage }
)(Schedule);
