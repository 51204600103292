import React, { useState, useEffect, useRef } from "react";
import { Card, Button, Form, Modal, Spinner } from "react-bootstrap";
import axios from "axios";
import Select from "react-select";
import firebase from "firebase";
import { Formik, Field, Form as FormComponent } from "formik";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { loadState } from "../../services";
import {
  API_BASE_URL,
  PLAN_ESTIMATE,
  USER_EXISTS,
  PLAN_STATUS,
  PLAN_CREATE,
  USER_WORKSPACES,
  EVENT_PLAN_STATUS,
} from "../../config";
import { postData } from "../../utils/index";

import EventPlans from "./Pricing/EventPlans";
import CoursePlans from "./Pricing/CoursePlans";
import Navbar from "./Navbar";

toast.configure();

const YourPlan = () => {
  // CONSTANTS
  const currentUser = firebase.auth().currentUser;
  const history = useHistory();

  const plans_inr = [
    {
      id: 0,
      price: "₹150",
      rooms: 1,
      days: 30,
      gateway_id: "INR",
    },
    {
      id: 1,
      price: "₹750",
      rooms: 5,
      days: 30,
      gateway_id: "INR",
    },
    {
      id: 2,
      price: "₹1500",
      rooms: 10,
      days: 60,
      gateway_id: "INR",
    },
    {
      id: 3,
      price: "₹3000",
      rooms: 20,
      days: 90,
      gateway_id: "INR",
    },
  ];

  const plans_usd = [
    {
      id: 0,
      price: "USD 3",
      rooms: 1,
      days: 30,
      gateway_id: "USD",
    },
    {
      id: 1,
      price: "USD 11",
      rooms: 5,
      days: 30,
      gateway_id: "USD",
    },
    {
      id: 2,
      price: "USD 21",
      rooms: 10,
      days: 60,
      gateway_id: "USD",
    },
    {
      id: 3,
      price: "USD 41",
      rooms: 20,
      days: 90,
      gateway_id: "USD",
    },
  ];

  // STATES
  const [plans, setPlans] = useState(plans_usd);
  const [country, setCountry] = useState("US");
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [customPlanDetails, setCustomPlanDetails] = useState({});
  const [customPlanAmount, setCustomPlanAmount] = useState({});
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [loggingSpinner, setLoggingSpinner] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [availablePlanHandler, setAvailablePlanHandler] = useState(false);
  const [customPlanSpinner, setCustomPlanSpinner] = useState(false);
  const [userWorkSpaces, setUserWorkSpaces] = useState([]);
  const [userCurrentPlan, setUserCurrentPlan] = useState({});
  const [defaultWorkSpace, setDefaultWorkSpace] = useState({});
  const [checkingActiveEventPlan, setCheckingActiveEventPlan] = useState(true);
  const [activeEventPlan, setActiveEventPlan] = useState(null);

  // REFS
  const pageRef = useRef(null);

  // FUNCTIONS
  useEffect(() => {
    // pageRef && pageRef.current.scrollIntoView();

    if (loadState("accessCountryCode") === "IN") {
      setCountry("IN");
      setPlans(plans_inr);
    } else {
      setCountry(loadState("accessCountryCode"));
      setPlans(plans_usd);
    }
  }, []);

  const getWorkspaces = async () => {
    try {
      const res = await axios.post(`${API_BASE_URL}${USER_WORKSPACES}`, {
        user_id: currentUser?.uid,
      });
      if (res.data.status === 200) {
        console.log(res.data.data.user_workspaces);
        let workspaces = res.data.data.user_workspaces.map((item) => {
          return { label: item.workspaceName, value: item.workspaceId };
        });
        setUserWorkSpaces(workspaces);
        setDefaultWorkSpace(workspaces[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const checkPlan = async () => {
    // Checking events and courses plans
    try {
      const response = await axios.post(`https://api.igesia.com${EVENT_PLAN_STATUS}`, {
        user_id: currentUser.uid,
      });

      if (response.status === 200) {
        const { data, message } = response.data;
        console.log("[Response] Checking events and courses plans: ", message);
        setActiveEventPlan(data);
        setCheckingActiveEventPlan(false);
      } else {
        setActiveEventPlan(null);
        setCheckingActiveEventPlan(false);
      }
    } catch (error) {
      console.log("[Error] Checking events and courses plans: ", error);
      setCheckingActiveEventPlan(false);
    }
  };

  useEffect(() => {
    if (currentUser && userWorkSpaces.length == 0) {
      getWorkspaces();
    }

    checkPlan();
  }, [currentUser]);

  const getCurrentUserPlan = async () => {
    try {
      console.log(defaultWorkSpace, "default workspace value");
      const res = await axios.post(`${API_BASE_URL}${PLAN_STATUS}`, {
        user_id: currentUser?.uid ?? "unauthorised",
        workspace_id: defaultWorkSpace?.value,
      });
      if (res.data.status === 200) {
        setUserCurrentPlan(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (currentUser && !userCurrentPlan?.planType) {
      getCurrentUserPlan();
    }
  }, [currentUser, defaultWorkSpace]);

  const selectedPlanHandler = async () => {
    if (!currentUser) {
      setShowLoginModal(true);
      setAvailablePlanHandler(false);
    } else {
      try {
        const res = await axios.post(`${API_BASE_URL}${PLAN_STATUS}`, {
          user_id: currentUser.uid,
          workspace_id: defaultWorkSpace.value,
        });
        if (res.data.data.planType === 0) {
          const response = await axios.post(`${API_BASE_URL}${PLAN_CREATE}`, {
            user_id: currentUser.uid,
            workspace_id: defaultWorkSpace?.value,
            plan_details: {
              validity: selectedPlan.validity,
              rooms: selectedPlan.rooms,
            },
            gateway_id: selectedPlan.gateway_id,
            payment_success_url:
              "https://platform.igesia.co/payment-successful",
            payment_cancel_url: "https://platform.igesia.co/payment-failed",
          });
          window.localStorage.setItem(
            "payment_reference_id",
            JSON.stringify(response.data.data.payment_reference_id)
          );
          //alert(response.data.data.payment_reference_id)
          window.location.href = response.data.data.payment_url;

          console.log(response.data.data);
        } else if (res.data.data.planType === 1) {
          history.push(`/my-plan`);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setAvailablePlanHandler(false);
      }
    }
  };

  const customPlanHandler = async () => {
    console.log(customPlanDetails);
    if (!currentUser) {
      setShowLoginModal(true);
      setCustomPlanSpinner(false);
    } else {
      try {
        const res = await axios.post(`${API_BASE_URL}${PLAN_STATUS}`, {
          user_id: currentUser?.uid,
          workspace_id: defaultWorkSpace?.value,
        });
        if (res.data.data.planType === 0) {
          const response = await axios.post(`${API_BASE_URL}${PLAN_CREATE}`, {
            user_id: currentUser?.uid,
            workspace_id: defaultWorkSpace?.value,
            plan_details: {
              validity: parseInt(customPlanDetails?.validity),
              rooms: parseInt(customPlanDetails?.rooms),
            },
            gateway_id: customPlanDetails.currency ?? "INR",
            payment_success_url: "https://example.com/success.html",
            payment_cancel_url: "https://example.com/cancel.html",
          });
          window.location.href = response.data.data.payment_url;
        } else if (res.data.data.planType === 1) {
          history.push(`/my-plan`);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setCustomPlanSpinner(false);
      }
    }
  };
  const LoginVaildationSchema = Yup.object().shape({
    email: Yup.string().required("Required"),
    password: Yup.string()
      .min(6, "Password must be atleast 6 characters long")
      .required("Required"),
  });

  const getCustomPlanDetails = async () => {
    try {
      const res = await axios.post(`${API_BASE_URL}${PLAN_ESTIMATE}`, {
        user_id: currentUser?.uid ?? "unauthorised",
        workspace_id: "LgT2sjzoZQwwKMGPnob2",
        plan_details: {
          validity: customPlanDetails?.validity,
          rooms: customPlanDetails?.rooms * 30,
        },
        gateway_id: customPlanDetails?.currency ?? "INR",
      });
      if (res.data.status === 200) {
        setCustomPlanAmount(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleLogin = async (resetForm, email, pass) => {
    try {
      setLoggingSpinner(true);
      postData(`${API_BASE_URL}${USER_EXISTS}`, { ["email"]: email }).then(
        async (response) => {
          firebase
            .auth()
            .signInWithEmailAndPassword(email, pass)
            .then(async (signedInUser) => {
              const res = await axios.post(
                `${API_BASE_URL}${USER_WORKSPACES}`,
                {
                  user_id: signedInUser?.user?.uid,
                }
              );
              if (!res?.data?.data?.user_workspaces?.length) {
                firebase
                  .firestore()
                  .collection("users")
                  .doc(`${signedInUser?.user?.uid}`)
                  .collection("rooms")
                  .doc("LgT2sjzoZQwwKMGPnob2")
                  .set({
                    rooms: [],
                    workspaceId: "LgT2sjzoZQwwKMGPnob2",
                  })
                  .then(() => {
                    console.log(
                      "Document successfully written! + added default workspace as igesia"
                    );
                  });
              }
              setShowLoginModal(false);

              const messageLoginSuccess = () => (
                <div className="d-flex align-items-center">
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="mr-2 my-0 text-success"
                  />
                  <p className="mb-0">Logged in successfully</p>
                </div>
              );
              toast.dark(messageLoginSuccess, {
                position: toast.POSITION.TOP_CENTER,
                hideProgressBar: false,
                autoClose: 3000,
              });
            })
            .catch((error) => {
              toast.error(error.message, {
                position: toast.POSITION.TOP_CENTER,
                hideProgressBar: true,
                autoClose: 3000,
              });
            });
        }
      );
    } catch (e) {
      toast.error("User not Found", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
      setTimeout(() => setLoggingSpinner(false), 1000);
    } finally {
      setTimeout(() => setLoggingSpinner(false), 1000);
    }
  };

  const handleShowLoginModal = async () => {
    setShowLoginModal(true);
  };

  return (
    <>
      <div className="overflow-hidden" ref={pageRef}>
        <div className="container-fluid pt-3 pb-5 bg-gradient-colourful">
          <div className="container pb-4">
            <Navbar />
            {console.log(userCurrentPlan, "user current plan")}
            <div className="text-white mb-5 mt-2">
              <h2 className="mb-3 text-s-1">Pricing</h2>
            </div>
          </div>
        </div>

        {/* Section: Event plans */}
        <EventPlans
          country={country}
          activePlan={activeEventPlan}
          checkingActivePlan={checkingActiveEventPlan}
          handleShowLoginModal={handleShowLoginModal}
          checkPlan={checkPlan}
        />

        {/* Section: Course plans */}
        <CoursePlans
          country={country}
          activePlan={activeEventPlan}
          checkingActivePlan={checkingActiveEventPlan}
          handleShowLoginModal={handleShowLoginModal}
          checkPlan={checkPlan}
        />

        {/* Section: Room plans */}
        <div
          className="container-fluid py-5"
          style={{
            backgroundColor: "#17191A",
            overflow: "hidden",
          }}
        >
          <div className="container py-5">
            <div className="row">
              <div className="col-md-8">
                <h2 className="mb-3 text-s-1 text-green">Rooms</h2>
                <p className="text-s-4 text-light">
                  Rooms can be connected to events and course platforms and
                  assigned to all your participants or cohorts. Native
                  productivity apps including in-built video to improve quality
                  of engagement.
                </p>
              </div>
            </div>
            <div className="mt-3 mt-md-4">
              {currentUser && (
                <div>
                  <h6 className="text-white text-uppercase font-weight-bold">
                    Select workspace
                  </h6>
                  <Form.Group>
                    <Select
                      value={defaultWorkSpace}
                      options={userWorkSpaces}
                      onChange={(e) => {
                        setDefaultWorkSpace(e);
                      }}
                    />
                  </Form.Group>
                </div>
              )}
              <div className="row mt-5">
                <div className="col-md-6 mb-4">
                  <Card className="">
                    <Card.Header style={{ backgroundColor: "#fff" }}>
                      <h2>Select a preset plan</h2>
                    </Card.Header>
                    <Card.Body style={{ backgroundColor: "#fff" }}>
                      <div className="row">
                        {plans.map((item, key) => (
                          <div key={key} className="col-md-6 pointer">
                            <div
                              className={
                                selectedPlan?.id === item.id
                                  ? "p-3 mb-2 mb-md-3 rounded border border-primary"
                                  : "p-3 mb-2 mb-md-3 rounded border"
                              }
                              onClick={() => setSelectedPlan(item)}
                            >
                              <h3>{item.price}</h3>
                              <p className="mb-0">{`${item.rooms} ${
                                item.rooms > 1 ? "rooms" : "room"
                              } for ${item.days} days`}</p>
                            </div>
                          </div>
                        ))}
                      </div>
                      <Button
                        variant="transparent"
                        className="border-primary col-12 my-2"
                        onClick={() => {
                          setAvailablePlanHandler(true);
                          selectedPlanHandler();
                        }}
                        disabled={availablePlanHandler || !selectedPlan}
                      >
                        {availablePlanHandler && (
                          <Spinner animation="border" size="sm" />
                        )}{" "}
                        CONTINUE
                      </Button>
                      {currentUser && userCurrentPlan?.planType === 1 && (
                        <div className="mt-3 d-flex">
                          <p className="align-items-center mb-0">
                            Looks like you already have an active plan,{" "}
                            <a
                              className="text-primary"
                              style={{ cursor: "pointer" }}
                              onClick={async () => {
                                history.push("/my-plan");
                              }}
                            >
                              check your plan
                            </a>
                            .
                          </p>
                        </div>
                      )}
                      {currentUser && (
                        <div className="mt-3 d-flex">
                          <p className="align-items-center mb-0">
                            Not{" "}
                            <b>
                              {currentUser?.displayName} ({currentUser?.email})
                            </b>
                            ?{" "}
                            <a
                              className="text-primary"
                              style={{ cursor: "pointer" }}
                              onClick={async () => {
                                await firebase.auth().signOut();

                                toast.dark("Logged out", {
                                  position: toast.POSITION.TOP_CENTER,
                                  hideProgressBar: true,
                                  autoClose: 3000,
                                });
                              }}
                            >
                              Logout
                            </a>
                          </p>
                        </div>
                      )}
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-md-6 mb-4">
                  <Card className="">
                    <Card.Header style={{ backgroundColor: "#fff" }}>
                      <h2>Customise your plan</h2>
                    </Card.Header>
                    <Card.Body style={{ backgroundColor: "#fff" }}>
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          getCustomPlanDetails();
                        }}
                      >
                        <Form.Group className="mb-3" controlId="rooms">
                          <Form.Label className="text-muted text-uppercase small font-weight-bold">
                            Rooms
                          </Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="Enter number of rooms"
                            required={true}
                            value={customPlanDetails?.rooms}
                            onChange={(e) => {
                              setCustomPlanDetails((prev) => {
                                const newState = {
                                  ...prev,
                                  rooms: e.target.value,
                                };
                                return newState;
                              });
                            }}
                            min={1}
                          />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="days">
                          <Form.Label className="text-muted text-uppercase small font-weight-bold">
                            Months
                          </Form.Label>
                          <Form.Control
                            type="number"
                            required={true}
                            placeholder="Enter number of months"
                            value={customPlanDetails?.validity}
                            onChange={(e) => {
                              setCustomPlanDetails((prev) => {
                                const newState = {
                                  ...prev,
                                  validity: e.target.value,
                                };
                                return newState;
                              });
                            }}
                            min={1}
                          />
                        </Form.Group>

                        <Form.Group>
                          <Form.Label className="text-muted text-uppercase small font-weight-bold">
                            Currency
                          </Form.Label>
                          <Select
                            placeholder="Select currency"
                            options={[
                              { value: "USD", label: "USD" },
                              { value: "INR", label: "INR" },
                            ]}
                            onChange={(e) => {
                              setCustomPlanDetails((prev) => {
                                const newState = { ...prev, currency: e.value };
                                return newState;
                              });
                            }}
                          />
                        </Form.Group>

                        <Button
                          variant="transparent"
                          className="border-primary col-12 my-2 text-uppercase"
                          type="submit"
                          disabled={
                            !customPlanDetails?.validity ||
                            !customPlanDetails?.rooms
                          }
                        >
                          Calculate Price
                        </Button>

                        {customPlanAmount?.plan_estimate && (
                          <h5 className="mb-2">
                            {`Total Amount ${
                              customPlanAmount?.gateway_id === "USD" ? "$" : "₹"
                            }${customPlanAmount?.plan_estimate}`}
                          </h5>
                        )}
                      </Form>
                      <Button
                        variant="transparent"
                        className="border-primary col-12 my-2"
                        onClick={() => {
                          setCustomPlanSpinner(true);
                          customPlanHandler();
                        }}
                        disabled={
                          !customPlanDetails?.validity ||
                          !customPlanDetails?.rooms ||
                          customPlanSpinner
                        }
                      >
                        CONTINUE
                      </Button>
                      {!currentUser && (
                        <div className="mt-3 d-flex">
                          <p className="align-items-center mb-0">
                            <a
                              className="text-primary"
                              style={{ cursor: "pointer" }}
                              onClick={handleShowLoginModal}
                            >
                              Click here
                            </a>
                            , to log into your account to buy a plan.
                          </p>
                        </div>
                      )}
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal: User log in */}
      <Modal
        centered
        show={showLoginModal}
        onHide={() => setShowLoginModal(false)}
      >
        <Modal.Header closeButton>
          <div>
            <p className="mb-0 lead">
              <b>Please fill in your details to login</b>
            </p>
            <p className="mb-0 small text-muted">
              Marked (<span className="text-danger">*</span>) fields are
              mandatory
            </p>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Formik
            validationSchema={LoginVaildationSchema}
            initialValues={{
              email: "",
              password: "",
            }}
            onSubmit={(values, { resetForm }) => {
              handleLogin(resetForm, values.email, values.password);
            }}
          >
            {({ errors, touched, isValidating, values }) => (
              <FormComponent>
                <div className="">
                  <div className="">
                    <div className="form-label-group">
                      <label
                        htmlFor="email"
                        className="mb-1 text-uppercase font-weight-bold text-muted small"
                      >
                        Email address <span className="text-danger">*</span>
                      </label>
                      <Field
                        name="email"
                        id="email"
                        type="email"
                        placeholder="Email"
                        className="form-control"
                      ></Field>
                      {errors.email && touched.email ? (
                        <div className="text-danger ml-2">{errors.email}</div>
                      ) : null}
                    </div>

                    <div className="form-label-group  mt-3">
                      <label
                        htmlFor="pass"
                        className="mb-1 text-uppercase font-weight-bold text-muted small"
                      >
                        Password <span className="text-danger">*</span>
                      </label>
                      <div className="d-flex align-items-center">
                        <Field
                          name="password"
                          id="password"
                          type={showPass ? "text" : "password"}
                          placeholder="Password"
                          className="form-control"
                        ></Field>
                        <div
                          className="p-2"
                          onClick={() => setShowPass(!showPass)}
                        >
                          <FontAwesomeIcon
                            icon={showPass ? faEye : faEyeSlash}
                            className="text-dark"
                          />
                        </div>
                      </div>
                      {errors.password && touched.password ? (
                        <div className="text-danger ml-2">
                          {errors.password}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="mt-3">
                  <Button
                    type="submit"
                    className="w-100 text-uppercase"
                    disabled={loggingSpinner}
                  >
                    {loggingSpinner && <Spinner animation="border" size="sm" />}{" "}
                    Login
                  </Button>
                </div>
              </FormComponent>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default YourPlan;
